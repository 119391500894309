export function timeFormatter(miliseconds: number): {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
} {
    const date = new Date(miliseconds);
    return {
        days: Math.floor(miliseconds / (24 * 60 * 60 * 1000)),
        hours: date.getUTCHours(),
        minutes: date.getUTCMinutes(),
        seconds: date.getUTCSeconds(),
    };
}

export function getFormattedStringTime(
    time: { days: number; hours: number; minutes: number; seconds: number },
    hideZerosHours: boolean,
    hideZerosMinutes = false,
    hideZerosSeconds = false,
    shouldPrefixWithZero = true
): string {
    const SLICE_START = -2;
    const DOUBLE_ZERO = '00';
    const SINGLE_ZERO = '0';

    let hours: string | number = time.hours + time.days * 24;

    if (time.hours + time.days * 24 > 10) {
        hours = hours.toString();
    } else {
        hours = shouldPrefixWithZero ? (SINGLE_ZERO + hours).slice(SLICE_START) : hours.toString();
    }

    const minutes = shouldPrefixWithZero ? (SINGLE_ZERO + time.minutes).slice(SLICE_START) : time.minutes.toString();
    const seconds = shouldPrefixWithZero ? (SINGLE_ZERO + time.seconds).slice(SLICE_START) : time.seconds.toString();

    const timeParts = [];

    if (!(hideZerosHours && (hours === DOUBLE_ZERO || hours === SINGLE_ZERO))) {
        timeParts.push(`${hours}h`);
    }

    if (!(hideZerosMinutes && (minutes === DOUBLE_ZERO || minutes === SINGLE_ZERO))) {
        timeParts.push(`${minutes}m`);
    }

    if (!(hideZerosSeconds && (seconds === DOUBLE_ZERO || seconds === SINGLE_ZERO))) {
        timeParts.push(`${seconds}s`);
    }

    return timeParts.join(' ');
}
