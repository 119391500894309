import { Injectable } from '@angular/core';
import { ErrorCorrectionApiService } from './error-correction-api.service';
import { ErrorCorrection } from '@data-terminal/shared-models';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ErrorCorrectionService {
    constructor(private readonly errorCorrectionApiService: ErrorCorrectionApiService) {}

    public doErrorCorrection(body: ErrorCorrection): Observable<boolean> {
        return this.errorCorrectionApiService.doErrorCorrection(body);
    }
}
