import { inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject, take, tap } from 'rxjs';

import { OrganizationInfo } from '@data-terminal/data-access';

import { OrganizationApiService } from './organization-api.service';

@Injectable({
    providedIn: 'root',
})
export class OrganizationService {
    readonly #organizationApiService = inject(OrganizationApiService);

    readonly #organizationInfo$ = new ReplaySubject<OrganizationInfo>(1);

    #organizationInfo!: OrganizationInfo;

    public fetchOrganizationInfo(): Observable<OrganizationInfo | undefined> {
        return this.#organizationApiService.getOrganizationInfo().pipe(
            take(1),
            tap((organizationInfo) => {
                if (organizationInfo) {
                    this.#organizationInfo = organizationInfo;
                    this.#organizationInfo$.next(organizationInfo);
                }
            })
        );
    }

    public get organizationInfo(): OrganizationInfo {
        return this.#organizationInfo;
    }

    public get organizationInfo$(): Observable<OrganizationInfo> {
        return this.#organizationInfo$.asObservable();
    }
}
