import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export const requireMinValueOptional: (controlName: string, minValue: number) => ValidatorFn = (
    controlName,
    minValue
) => {
    return (formGroup: AbstractControl): ValidationErrors | null => {
        if (
            formGroup.get(controlName)?.value !== null &&
            formGroup.get(controlName)?.value !== '' &&
            formGroup.get(controlName)?.value !== undefined &&
            (typeof formGroup.get(controlName)?.value !== 'number' ||
                (formGroup.get(controlName)?.value as number) < minValue)
        ) {
            formGroup.get(controlName)?.setErrors({ required: true });
            return { required: true };
        }
        if (
            (formGroup.get(controlName) !== null && (formGroup.get(controlName)?.value as number) >= minValue) ||
            typeof formGroup.get(controlName)?.value === 'number'
        ) {
            formGroup.get(controlName)?.setErrors(null);
        }
        return null;
    };
};
