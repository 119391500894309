import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ENGLISH_LOCALE, ENGLISH_UK_LOCALE } from '@data-terminal/app';

@Pipe({
    name: 'dateFormatterWithoutYear',
    standalone: true,
})
export class DateFormatterWithoutYear implements PipeTransform {
    #translate = inject(TranslateService);

    transform(value: number): string | null {
        const date = new Date(value);

        // it is needed here, without this we end up with en-US format for both cases
        const locale = this.#translate.currentLang === ENGLISH_LOCALE ? ENGLISH_UK_LOCALE : this.#translate.currentLang;

        const options: Intl.DateTimeFormatOptions = {
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        };

        return Intl.DateTimeFormat(locale, options).format(date);
    }
}
