import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { CCAuthService } from '@heidelberg/control-center-frontend-integration/auth';
import { CCPersonOrganizationMembership } from '@heidelberg/control-center-frontend-integration/cc';

import { NavData, NavLink, ROUTE_PATH, RouteData, RouteState } from '@data-terminal/shared-models';
import { getParams, LOCATION } from '@data-terminal/utils';

import { ActivatedRouteDataService } from '../activated-route-data/activated-route-data.service';
import { IS_PRODUCTION } from '../injection-tokens';
import { CloudSessionService } from '../session-service';
import { findMembership } from '../user-identity';

type RouteWithData = Route & { data: RouteData };

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private readonly _navData$ = new BehaviorSubject<NavData>({ navLinks: [] } as NavData);
    private readonly history: string[] = [];

    public get navData$(): Observable<NavData> {
        return this._navData$.asObservable();
    }

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly latestActivatedRouteDataService: ActivatedRouteDataService,
        private readonly ccAuthService: CCAuthService,
        private readonly cloudSessionService: CloudSessionService,
        @Inject(LOCATION) private readonly location: Location,
        @Inject(IS_PRODUCTION) private readonly isProduction: boolean
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.history.push(event.urlAfterRedirects);
            }
        });
    }

    public back(): void {
        this.history.pop();
        if (this.history.length > 0) {
            const url = this.history.pop();
            if (url) {
                this.router.navigateByUrl(url);
                return;
            }
        }
        const lastRoute = this.cloudSessionService.getSessionData()?.lastRoute;
        if (lastRoute) {
            this.router.navigateByUrl(lastRoute);
            return;
        }
        const startupOrganization = this.ccAuthService.getCurrentMembership()?.organizationId || '';
        this.router.navigateByUrl(`/${startupOrganization}/${ROUTE_PATH.dashboard}`);
    }

    public initRoutingTracking(): void {
        this.latestActivatedRouteDataService.activatedRouteData$
            .pipe(
                filter(
                    () =>
                        !this.router.url.endsWith(`/${ROUTE_PATH.error}`) &&
                        !this.router.url.endsWith(`/${ROUTE_PATH.userNotFound}`) &&
                        !this.router.url.endsWith(`/${ROUTE_PATH.farewell}`) &&
                        !this.router.url.endsWith(`/${ROUTE_PATH.welcome}`) &&
                        !this.router.url.includes(`/${ROUTE_PATH.inactivityTimer}/`)
                ),
                switchMap((data) => this.cloudSessionService.updateLastRoute(this.router.url).pipe(map(() => data)))
            )
            .subscribe((data) => {
                this._navData$.next({
                    ...data,
                    navLinks: data.isNavigationHidden ? [] : this.createNavLinks(data.subNavigation || []),
                });
            });
    }

    public goToChildRoute(childPath: string[], state?: RouteState): void {
        const currentUrl = this.router.parseUrl(this.router.routerState.snapshot.url);
        const urlSegments = currentUrl.root.children.primary.segments.map((segment) => segment.path);
        const queryParams = currentUrl.queryParams;
        this.router.navigate([...urlSegments, ...childPath], {
            queryParams: queryParams || {},
            state: state || {},
        });
    }

    public goToParentRoute(): void {
        const currentUrl = this.router.parseUrl(this.router.routerState.snapshot.url);
        const urlSegments = currentUrl.root.children.primary.segments.map((segment) => segment.path);
        const goToParentSegments = this._navData$.value?.goToParentSegments || -1;
        urlSegments.splice(goToParentSegments);
        const queryParams = currentUrl.queryParams;
        this.router.navigate([...urlSegments], { queryParams });
    }

    public setCurrentPageTitle(title: string): void {
        this._navData$.next({ ...this._navData$.value, title });
    }

    public getCurrentPath(): string {
        return this.router.url;
    }

    public redirectToInitialRoute(): void {
        const startupOrganization = this.ccAuthService.getCurrentMembership()?.organizationId || '';

        const lastRoute = this.cloudSessionService.getSessionData()?.lastRoute;
        const urlFromOauthState = lastRoute || '';

        if (
            this.isOrganizationIdOnUrlValid(urlFromOauthState, this.ccAuthService.getCurrentUser()?.memberships || [])
        ) {
            this.router.navigateByUrl(urlFromOauthState);
        } else {
            const isCurrentUrlValid = this.location.pathname.indexOf(startupOrganization) > -1;
            const isWelcomePage = this.location.pathname.endsWith(ROUTE_PATH.welcome);

            if ((!isCurrentUrlValid || isWelcomePage) && startupOrganization) {
                this.router.navigateByUrl(`/${startupOrganization}`);
            } else {
                const url = this.location.href.replace(this.location.origin, '');
                this.router.navigateByUrl(url);
            }
        }
    }

    private isOrganizationIdOnUrlValid(url: string, memberships: CCPersonOrganizationMembership[]): boolean {
        const dashboardIdSegment = url.split('/').filter((s) => !!s)[0];
        return !!findMembership(dashboardIdSegment, memberships);
    }

    private createNavLinks(children: Route[]): NavLink[] {
        return children
            .filter((route) => !(route.data?.isDisabledOnProd && this.isProduction))
            .filter((route) => !route.data?.isNavigationHidden)
            .map((route) => ({
                path: this.createPath(route as RouteWithData),
                title: route.data?.navTitle,
                order: route.data?.order,
                id: route.data?.id,
                isCustomTab: route.data?.isCustomTab || false,
            }))
            .sort((routeA, routeB) => routeA.order - routeB.order);
    }

    private createPath(route: RouteWithData): string {
        const params = getParams(this.route);
        let parent = '';
        let childUrl = '';
        let childrenOfChild = '';
        Object.keys(params).forEach((key) => {
            if (route.path?.includes(key)) {
                parent = params[key];
                childUrl = route.path?.replace(':' + key, params[key]);
            }
            if (route?.children?.length && route.children[0].path?.includes(key)) {
                childrenOfChild = params[key];
            }
        });
        const parentUrl = this.getCurrentPath().slice(0, this.getCurrentPath().indexOf(parent));
        return `${parentUrl}${childUrl}/${childrenOfChild}`;
    }
}

export function isSecondLevel(source: Observable<NavData>): Observable<boolean> {
    return source.pipe(
        map((navData) => !!navData.isSecondLevel),
        distinctUntilChanged()
    );
}

export function getNavLinks(source: Observable<NavData>): Observable<NavLink[]> {
    return source.pipe(
        distinctUntilChanged(
            (previous, current) => JSON.stringify(previous.navLinks) === JSON.stringify(current.navLinks)
        ),
        map((navData) => navData.navLinks)
    );
}

export function getCurrentPageTitle(source: Observable<NavData>): Observable<string> {
    return source.pipe(
        map((navData) => navData.title || 'DC.APP.TITLE'),
        distinctUntilChanged()
    );
}

export function isFooterHidden(source: Observable<NavData>): Observable<boolean> {
    return source.pipe(
        map((navData) => !!navData.isLegalLineHidden),
        distinctUntilChanged()
    );
}

export function isNavigationHidden(source: Observable<NavData>): Observable<boolean> {
    return source.pipe(
        map((navData) => !navData.isNavigationHidden),
        distinctUntilChanged()
    );
}

export function isCustomTab(source: Observable<NavData>): Observable<string> {
    return source.pipe(
        map((navData) => navData.customTabs || 'STANDARD'),
        distinctUntilChanged()
    );
}
