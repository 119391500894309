import { NgModule } from '@angular/core';
import { IsDataPresentDirective } from '../directives/is-data-present.directive';
import { IsErrorDirective } from '../directives/is-error.directive';
import { IsLoadingDirective } from '../directives/is-loading.directive';

@NgModule({
    declarations: [IsDataPresentDirective, IsErrorDirective, IsLoadingDirective],
    exports: [IsDataPresentDirective, IsErrorDirective, IsLoadingDirective],
})
export class MetadataDirectivesModule {}
