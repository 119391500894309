import { Machine } from './machine';

export interface User {
    email: string;
    firstName: string;
    lastName: string;
    userId: string;
    needsPWD: boolean;
    zaikioId: string;
    userPermissions: Permission[];
    machinePermissions: MachinePermissions[];
    userMachines: Machine[];
}

export interface MachinePermissions {
    id: string;
    userMachinePermissions: Permission[];
}

export enum Permission {
    PAUSE_STOP_OPERATIONS = 'PAUSE_STOP_OPERATIONS',
    ACTIVATE_SFDC = 'ACTIVATE_SFDC',
    CHANGE_COUNTER = 'CHANGE_COUNTER',
    TAKE_OVER = 'TAKE_OVER',
    JOIN = 'JOIN',
    PRINT_PALLET_SHEET = 'PRINT_PALLET_SHEET',
    SET_FILTERS = 'SET_FILTERS',
}

export interface CloudSession {
    lastRoute: string;
    machines: string[];
    updatedAt?: string;
}
