import { inject, Injectable } from '@angular/core';

import {
    ConfigService,
    Stage,
    STAGE_DEV,
    STAGE_LOCAL,
    STAGE_STAGE,
} from '@heidelberg/control-center-frontend-integration';
import { DeployEnvironment } from '@heidelberg/control-center-frontend-integration/auth';

import { APP_CLIENT_ID } from '../injection-tokens';

@Injectable({
    providedIn: 'root',
})
export class AppConfigService {
    readonly #configService = inject(ConfigService);
    readonly #appClientId = inject(APP_CLIENT_ID);

    private get stage(): Stage {
        return this.#configService.stage;
    }

    public getStage(): Stage {
        return this.stage;
    }

    public getCCStage(): DeployEnvironment {
        const stage = this.getStage();
        switch (stage) {
            case STAGE_LOCAL:
                return 'stage';
            case STAGE_DEV:
                return 'stage';
            case STAGE_STAGE:
                return 'stage';
            default:
                break;
        }

        return 'prod';
    }

    public getOAuthClientId(): string {
        return this.#appClientId;
    }
}
