import { Routes } from '@angular/router';
import { CloudToolbarBreadcrumb } from '@heidelberg/hdmui-angular';
import { Activity } from './operation';

export interface NavLink {
    path: string;
    title: string;
    order: number;
    icon?: string;
    id?: string;
}

export interface RouteData {
    title?: string;
    order?: number;
    icon?: string;
    isLegalLineHidden?: boolean;
    isDisabledOnProd?: boolean;
    isNavigationHidden?: boolean;
    isSecondLevel?: boolean;
    navTitle?: string;
    subNavigation?: Routes[];
    breadcrumbs?: CloudToolbarBreadcrumb[];
    goToParentSegments?: number;
    activities?: Activity[];
    customTabs?: string;
}

export interface NavData extends RouteData {
    navLinks: NavLink[];
}

export enum ROUTE_PARAMS {
    orgId = 'orgId',
    machineId = 'machineId',
    primaryKey = 'primaryKey',
}

export enum ROUTE_PATH {
    error = 'error',
    userNotFound = 'user-not-found',
    dashboard = 'dashboard',
    operations = 'operations',
    timeLogging = 'time-logging',
    activities = 'activities',
    settings = 'settings',
    settingsConfirmation = 'confirmations',
    settingsDataTransfer = 'datatransfer',
    welcome = 'welcome',
    farewell = 'farewell',
    inactivityTimer = 'inactivity-timer',
    notifications = 'notifications',
}

export interface RouteState {
    [p: string]: any; // eslint-disable-line
}
