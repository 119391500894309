import { Component, Inject, NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    ActionType,
    CANCEL_LIST,
    DataTerminalErrorMsg,
    ErrorCode,
    ErrorCorrection,
    LOCAL_LIST,
    RequestType,
    ROUTE_PARAMS,
} from '@data-terminal/shared-models';
import { HdmuiBaseDialogComponent, HdmuiComponentsModule } from '@heidelberg/hdmui-angular';
import { NotificationItem } from 'projects/shared-models/src/lib/notification.';
import { ErrorCorrectionService } from '../../../services/error-correction.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { getAllParams } from '../../../../../projects/feature-workstation-details/src/lib/services/resolvers/get-params-from-path.function';
import { GenericUpdatesService } from '../../../../../projects/feature-workstation-details/src/lib/services/generic-updates/generic-updates.service';
import { take } from 'rxjs';
@Component({
    selector: 'app-error-dialog',
    templateUrl: './error-dialog.component.html',
    styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent {
    public DIALOG_OPTION_OK_CANCLE = HdmuiBaseDialogComponent.OPTION_OK_CANCEL;
    public DIALOG_OPTION_CANCLE = HdmuiBaseDialogComponent.OPTION_CANCEL;
    public errorMessage: NotificationItem;
    public errorList: DataTerminalErrorMsg[] = [];

    public cancleOnly: boolean;
    public localRetry: boolean;

    constructor(
        private readonly dialogRef: MatDialogRef<ErrorDialogComponent>,
        private readonly errorCorrectionService: ErrorCorrectionService,
        private readonly activatedRoute: ActivatedRoute,
        @Inject(MAT_DIALOG_DATA)
        public data: { errorCode: string; requestType: RequestType; machineId: string; errorMessage: string },
        private readonly genericUpdateService: GenericUpdatesService
    ) {
        try {
            this.errorMessage = {
                request: data.requestType,
                payload: JSON.parse(data.errorMessage),
                machineId: data.machineId,
            };
        } catch (e) {
            this.errorMessage = {
                request: this.buildErrorMessageRequestByErrorCode(data.errorCode as ErrorCode),
                payload: {},
                machineId: '',
            };
        }
        if (
            this.errorMessage.request === RequestType.SIGNIN ||
            this.errorMessage.request === RequestType.SIGNOFF_SHIFTEND
        ) {
            this.errorList = this.errorMessage.payload as DataTerminalErrorMsg[];
        }
        this.cancleOnly = !!CANCEL_LIST.find((ele) => ele === this.errorMessage.request);
        this.localRetry = !!LOCAL_LIST.find((ele) => ele === this.errorMessage.request);
    }

    private buildErrorMessageRequestByErrorCode(errorCode: ErrorCode): string {
        if (errorCode === ErrorCode.ERR_ONPREM_BACKEND_DISCONNECTED) {
            return RequestType.ONPREM_BACKEND_DISCONNECTED;
        }
        return RequestType.OTHER;
    }

    public retryClick(): void {
        this.sendErrorCorrectionRequest(ActionType.RETRY);
    }

    public localRetryClick(): void {
        this.dialogRef.close(this.errorMessage);
    }

    public cancleClick(): void {
        this.sendErrorCorrectionRequest(ActionType.CANCEL);
    }

    private sendErrorCorrectionRequest(actionType: ActionType): void {
        const body: ErrorCorrection = {
            actionType: actionType,
            request: this.errorMessage.request as RequestType,
            deviceId: this.errorMessage.machineId,
            payload: JSON.stringify(this.errorMessage.payload),
        };
        this.errorCorrectionService
            .doErrorCorrection(body)
            .pipe(take(1))
            .subscribe(() => {
                const primaryKey = getAllParams(this.activatedRoute.snapshot)[ROUTE_PARAMS.primaryKey];
                if ('primaryKey' in this.errorMessage.payload) {
                    if (primaryKey === this.errorMessage.payload.primaryKey) {
                        this.genericUpdateService.updateOperation(primaryKey);
                    }
                }
            });
        this.closeDialog();
    }

    closeDialog(): void {
        this.dialogRef.close(false);
    }
}

@NgModule({
    declarations: [ErrorDialogComponent],
    exports: [ErrorDialogComponent],
    imports: [HdmuiComponentsModule, CommonModule, MatButtonModule, TranslateModule],
})
export class ErrorDialogModule {}
