import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, Optional, makeEnvironmentProviders, APP_INITIALIZER } from '@angular/core';
import { ReplaySubject, Subject, firstValueFrom, timer, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Duration, DateTime } from 'luxon';
import * as i1 from '@angular/router';
import * as i2 from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import * as i3 from 'angular-oauth2-oidc';
import { provideOAuthClient } from 'angular-oauth2-oidc';
const CC_APP_AUTH_CONFIG = new InjectionToken('cc-app-auth-config');
const CC_REMOTE_CONFIG = new InjectionToken('cc-remote-config');
const CC_SESSION_READY_CALLBACK = new InjectionToken('cc-session-ready-callback');
const environmentConfig = {
  dev: {
    infoUrl: 'https://cc.dev.heidelberg.cloud/.well-known/cc-info',
    ccBackofficeIssuer: 'cc-backoffice'
  },
  stage: {
    infoUrl: 'https://cc.stage.heidelberg.cloud/.well-known/cc-info',
    ccBackofficeIssuer: 'cc-backoffice'
  },
  prod: {
    infoUrl: 'https://home.plus.heidelberg.com/.well-known/cc-info',
    ccBackofficeIssuer: 'cc-backoffice'
  }
};
var RedirectMode;
(function (RedirectMode) {
  RedirectMode["CheckSession"] = "CheckSession";
  RedirectMode["ForceLogin"] = "ForceLogin";
})(RedirectMode || (RedirectMode = {}));
const KEY_ZAIKIO_LOGIN_FAILED = 'login_failed';
const SESSION_STORAGE_URL_KEY = 'storedUrl';
const LOCAL_STORAGE_KEY_ORGANIZATION = 'currentOrg';
const KEY_ZAIKIO_LOGIN_EXPIRATION = Duration.fromObject({
  minutes: 1
});
class CCAuthService {
  isInitialLoadFinished() {
    return this.initialLoadFinished;
  }
  constructor(ccAppAuthConfig, ccRemoteConfig, ccSessionReadyCallback, router, http, oauthService) {
    this.ccAppAuthConfig = ccAppAuthConfig;
    this.ccRemoteConfig = ccRemoteConfig;
    this.ccSessionReadyCallback = ccSessionReadyCallback;
    this.router = router;
    this.http = http;
    this.oauthService = oauthService;
    this.sessionChanged = new ReplaySubject(1);
    /**
     * Subscribe to this observable to get the currently active session. This is will only be triggered once there is a valid session available.
     */
    this.currentSession$ = this.sessionChanged.asObservable();
    this.sessionExpired = new Subject();
    /**
     * Subscribe to this observable to get notified when access token is expired. If there is no subscription, a page reload is initiated.
    */
    this.sessionExpired$ = this.sessionExpired.asObservable();
    this.initialLoadFinished = false;
    this.orgMembershipTokenSubject = new Subject();
    /**
     * Subscribe to this observable to get notified when current orgMembership token has changed.
     */
    this.orgMembershipTokenChanged$ = this.orgMembershipTokenSubject.asObservable();
    this.lastTimer = new Date().getTime();
    //
    console.log('construct CCAuthService', this.ccAppAuthConfig);
  }
  requiresOrganizationContext() {
    return this.ccAppAuthConfig.requiresOrganizationContext;
  }
  async onAppStart() {
    let redirectMode = RedirectMode.CheckSession;
    const reloadTime = localStorage.getItem(KEY_ZAIKIO_LOGIN_FAILED);
    // TODO: maybe store complete queryparams in storage and restore later to allow arbitrary options
    const localStorageOrg = this.getOrgIdFromLocalStorage();
    const urlOrgId = this.tryReadOrgFromUrl();
    const config = environmentConfig[this.ccAppAuthConfig.ccStage];
    this.remoteConfig = this.ccRemoteConfig ?? (await firstValueFrom(this.http.get(config.infoUrl)));
    if (this.ccAppAuthConfig.mode) {
      this.remoteConfig.mode = this.ccAppAuthConfig.mode;
    }
    if (this.ccRemoteConfig?.mode === 'idService' || reloadTime && DateTime.now() < DateTime.fromISO(reloadTime)) {
      redirectMode = RedirectMode.ForceLogin;
    }
    if (this.remoteConfig.mode === 'idService') {
      this.oauthService.events.subscribe(event => {
        if (event.type === 'token_received' && this.session?.activeMembership) {
          this.orgMembershipTokenSubject.next({
            access_token: this.oauthService.getAccessToken(),
            expires_in: this.oauthService.getAccessTokenExpiration(),
            token_type: 'ORGANIZATION_MEMBER',
            organizationId: this.session.activeMembership.organizationId,
            personId: this.session.user.id,
            roles: this.session.activeMembership.ccRoles,
            scope: ''
          });
        }
      });
    }
    const authConfig = await buildOAuthConfig(this.remoteConfig, this.ccAppAuthConfig, redirectMode, urlOrgId ?? localStorageOrg);
    this.oauthService.configure(authConfig);
    if (!sessionStorage.getItem(SESSION_STORAGE_URL_KEY) && !window.location.pathname.endsWith('/sign/in') && !window.location.hash.endsWith('/sign/in')) {
      if (window.location.hash) {
        sessionStorage.setItem(SESSION_STORAGE_URL_KEY, window.location.hash.substring(1));
      } else {
        sessionStorage.setItem(SESSION_STORAGE_URL_KEY, window.location.pathname);
      }
    }
    this.oauthService.setupAutomaticSilentRefresh();
    try {
      await this.oauthService.tryLogin();
    } catch (err) {
      // WARNING: The prompt=none parameter, will lead to an error, if a user is not logged in.
      this.initialLoadFinished = true;
      console.log(err);
      if (err.params) {
        if (err.params.error === 'access_denied' && err.params.error_description === 'user_not_logged_in') {
          // Redirect to ControlCenter if th user is currently not logged in.
          // Memorize for later, that the zaikio login failed, so that we can distinguish first from second attempt.
          localStorage.setItem(KEY_ZAIKIO_LOGIN_FAILED, DateTime.now().plus(KEY_ZAIKIO_LOGIN_EXPIRATION).toISO());
          // Remember to set the right return parameters and your app id
          if (this.remoteConfig.mode === 'zaikio') {
            window.location.assign(`${this.remoteConfig.control_center_url}?flow=login&appId=${this.ccAppAuthConfig.appId}&returnUrl=${window.location.origin}`);
          } else {
            // ??
          }
          return;
        }
      }
      throw err;
    }
    if (!this.oauthService.hasValidAccessToken()) {
      // User is not logged in. Library will do redirect with Zaikio
      // Workaround for expired tokens
      if (this.oauthService.getAccessToken()) {
        this.oauthService.logOut();
      }
      this.oauthService.initLoginFlow();
      // just return, because App is finished here.
      return;
    }
    try {
      const tokenOrgId = this.oauthService.getCustomTokenResponseProperty('organizationId');
      // must not check against localStorageOrg! That would cause infinite redirect.
      if (this.remoteConfig?.mode === 'idService' && urlOrgId && tokenOrgId !== urlOrgId) {
        console.log(`token orgId ${tokenOrgId} does not match requested orgId ${urlOrgId}, force redirect.`);
        this.oauthService.logOut(true);
        this.oauthService.initLoginFlow();
        return;
      }
      // User is logged in. Get Person data from Control Center
      await this.handleLoginSuccess();
      return this.session;
    } catch (err) {
      console.log(err);
      this.initialLoadFinished = true;
      throw err;
    }
  }
  createSessionFromDirectoryUser(user) {
    const s = {
      user: user
    };
    return s;
  }
  async handleLoginSuccess() {
    const u = await this.oauthService.loadUserProfile();
    // Build your session object from person data.
    // You may adjust your session object to your needs (e.g. calculate Permissions)
    const session = this.createSessionFromDirectoryUser(u.info);
    this.session = session;
    console.info('Session created!');
    if (this.remoteConfig?.mode === 'idService') {
      await this.handleIdServiceLoginSuccess();
    } else {
      await this.handleZaikioLoginSuccess();
    }
    session.appContext = await this.ccSessionReadyCallback?.({
      ...this.session,
      appContext: undefined
    });
    const storedURL = sessionStorage.getItem(SESSION_STORAGE_URL_KEY);
    if (storedURL) {
      sessionStorage.removeItem(SESSION_STORAGE_URL_KEY);
      try {
        await this.router.navigateByUrl(storedURL, {
          replaceUrl: true
        });
      } catch (err) {
        console.warn(`Route not found: ${storedURL}`, err);
        void this.router.navigate(['/']);
      }
    }
    this.initialLoadFinished = true;
  }
  async handleIdServiceLoginSuccess() {
    const orgId = this.oauthService.getCustomTokenResponseProperty('organizationId');
    if (typeof orgId === 'string' && orgId) {
      this.setCurrentOrganisationId(orgId);
    } else if (this.ccAppAuthConfig.requiresOrganizationContext) {
      throw new Error('No organization context provided by idService but app requires it.');
    }
  }
  async handleZaikioLoginSuccess() {
    if (!this.session) {
      return;
    }
    const savedOrganizationId = this.getOrgIdFromLocalStorage();
    if (savedOrganizationId) {
      // try switch to organization
      this.setCurrentOrganisationId(savedOrganizationId);
    }
    if (!this.session.activeMembership) {
      // Take first membership as default
      if (this.session.user.memberships.length > 0) {
        this.setCurrentOrganisationId(this.session.user.memberships[0].organizationId);
      } else {
        // if the user has no memberships, set to "NO Organization".
        this.setCurrentOrganisationId('');
        this.currentCCPersonOrganizationMembershipToken = undefined;
        if (this.ccAppAuthConfig.requiresOrganizationContext) {
          throw new Error('User has no memberships but app requires organization context.');
        }
      }
    }
    if (this.session.activeMembership && this.ccAppAuthConfig.requiresOrganizationContext) {
      // get org token
      console.log('creating organization token early');
      await this.createOrGetOrgMembershipToken(this.session.activeMembership.organizationId);
    }
  }
  isAuthenticated() {
    return this.session !== undefined;
  }
  getCurrentUser() {
    return this.session?.user;
  }
  getCurrentMembership() {
    return this.session?.activeMembership;
  }
  isTokenOutdated(tokenHolder) {
    // 5 Minutes buffer
    if (tokenHolder.expiringAt.getTime() - new Date().getTime() < 5 * 60 * 1000) {
      return true;
    }
    return false;
  }
  /**
   * Returns the current active access token.
   * This is an org-membership token if you configured your app with `requiresOrganizationContext=true`, a person token otherwise.
   *
   * If the token is expired, `sessionExpired$` will be triggered and an empty string returned.
   *
   * Note that if there is no subscription for `sessionExpired$` a page reload  will be triggered.
   * @returns the current active access token or empty string.
   */
  getPersonToken() {
    const date = new Date(this.oauthService.getAccessTokenExpiration());
    if (date.getTime() < new Date().getTime()) {
      console.log('Person token expired', date);
      if (this.sessionExpired.observed) {
        this.sessionExpired.next();
        return '';
      } else {
        window.location.reload();
      }
    }
    return this.oauthService.getAccessToken();
  }
  getPersonTokenExpiration() {
    return this.oauthService.getAccessTokenExpiration();
  }
  /**
   * Returns an OrgMembership token for the currently active organization. The result will be cached and used on on consecutive calls.
   * @returns OrgMembership token or `undefined` if no current organization is set.
   */
  async getCurrentOrgMembershipToken() {
    const orgId = this.getCurrentMembership()?.organizationId;
    if (orgId) {
      return this.createOrGetOrgMembershipToken(orgId);
    }
    if (this.ccAppAuthConfig.requiresOrganizationContext) {
      throw new Error('No current membership.');
    }
    return undefined;
  }
  /**
   * Returns an OrgMembership token for the provided organization. The result will be cached and used on on consecutive calls.
   *
   * You cannot retrieve tokens for organizations the current user is not member in.
   *
   * You will not be able to request tokens for organizations other than the current one in the future.
   *
   * Note that this method does not check for a valid membership before making a request,
   * so you will receive a unauthorized error if current user is not member of that organization.
   * @param organizationId organization id
   * @returns OrgMembership token or `undefined` if no current organization is set.
   */
  async createOrGetOrgMembershipToken(organizationId) {
    if (this.remoteConfig?.mode === 'idService') {
      if (this.session?.activeMembership?.organizationId === organizationId) {
        // access token is already orgmembership-token
        return this.oauthService.getAccessToken();
      } else {
        throw new Error('organizationId must match the current active membership.');
      }
    }
    if (!this.currentCCPersonOrganizationMembershipToken || this.isTokenOutdated(this.currentCCPersonOrganizationMembershipToken) || organizationId !== this.currentCCPersonOrganizationMembershipToken.token.organizationId) {
      const alreadyLoading = this.currentTokenRequest?.organizationId === organizationId;
      if (alreadyLoading) {
        const token = await firstValueFrom(this.orgMembershipTokenSubject);
        console.log('Already loaded token taken: ', this.currentCCPersonOrganizationMembershipToken?.expiringAt);
        return token.access_token;
      } else {
        this.currentTokenRequest = {
          organizationId
        };
        const url = `${this.remoteConfig.control_center_url}/api/public/v1/authorize/organizations/${organizationId}`;
        const token = await firstValueFrom(this.http.post(url, '', {
          headers: {
            Authorization: `Bearer ${this.getPersonToken()}`
          }
        }));
        this.currentCCPersonOrganizationMembershipToken = {
          token,
          expiringAt: new Date(new Date().getTime() + token.expires_in * 1000)
        };
        this.orgMembershipTokenSubject.next(token);
        this.currentTokenRequest = undefined;
        console.log('Token expires at: ', this.currentCCPersonOrganizationMembershipToken.expiringAt);
        return token.access_token;
      }
    } else {
      return this.currentCCPersonOrganizationMembershipToken.token.access_token;
    }
  }
  setCurrentOrganisationId(id) {
    if (!this.session) {
      throw new Error('No session found');
    }
    const membership = this.session.user.memberships.find(m => m.organization.id === id);
    if (membership) {
      this.setOrgIdToLocalStorage(id);
      this.session.activeMembership = membership;
    } else {
      this.setOrgIdToLocalStorage('');
      this.session.activeMembership = undefined;
      this.currentCCPersonOrganizationMembershipToken = undefined;
    }
    this.sessionChanged.next(this.session);
  }
  setOrgIdToLocalStorage(orgId) {
    localStorage.setItem(LOCAL_STORAGE_KEY_ORGANIZATION, orgId);
  }
  getOrgIdFromLocalStorage() {
    return localStorage.getItem(LOCAL_STORAGE_KEY_ORGANIZATION) ?? undefined;
  }
  tryReadOrgFromUrl() {
    const queryString = window.location.search;
    if (queryString) {
      const urlParams = new URLSearchParams(queryString);
      const orgId = urlParams.get('organization_id');
      if (orgId) {
        this.setOrgIdToLocalStorage(orgId);
        return orgId;
      }
    }
    return this.getOrgIdFromLocalStorage();
  }
  // reload timer, if app was too long in background
  initTimer() {
    this.timer = timer(10000, 6000 * 1);
    this.timer.subscribe(() => {
      const currentTime = new Date().getTime();
      if (currentTime - this.lastTimer > 60000 * 60) {
        window.location.reload();
      }
      this.lastTimer = currentTime;
    });
  }
  /**
   * Logs out the current user and redirects to Customer Portal logout endpoint.
   */
  signOut() {
    this.internalSignOut(this.remoteConfig.modes[this.remoteConfig.mode].end_session_endpoint);
  }
  /**
   * Logs out the current user and redirects to `/`.
   *
   * This does not log out the user for other apps.
   */
  localSignOut() {
    this.internalSignOut('/');
  }
  internalSignOut(followUrl) {
    this.currentCCPersonOrganizationMembershipToken = undefined;
    this.currentTokenRequest = undefined;
    this.oauthService.logOut();
    window.location.assign(followUrl);
  }
  static {
    this.ɵfac = function CCAuthService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CCAuthService)(i0.ɵɵinject(CC_APP_AUTH_CONFIG), i0.ɵɵinject(CC_REMOTE_CONFIG, 8), i0.ɵɵinject(CC_SESSION_READY_CALLBACK, 8), i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.OAuthService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CCAuthService,
      factory: CCAuthService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CCAuthService, [{
    type: Injectable
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [CC_APP_AUTH_CONFIG]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [CC_REMOTE_CONFIG]
    }, {
      type: Optional
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [CC_SESSION_READY_CALLBACK]
    }, {
      type: Optional
    }]
  }, {
    type: i1.Router
  }, {
    type: i2.HttpClient
  }, {
    type: i3.OAuthService
  }], null);
})();
function initAppSession(service) {
  service.initTimer();
  return async () => {
    await service.onAppStart();
  };
}
async function buildOAuthConfig(remoteConfig, ccAppAuthConfig, redirectMode, organizationId) {
  let scope;
  if (remoteConfig.mode === 'zaikio') {
    scope = 'zaikio.person.r';
  } else if (organizationId) {
    scope = `Org/${organizationId} profile`;
  } else {
    scope = 'profile';
  }
  const openIdConfig = remoteConfig.modes[remoteConfig.mode];
  const oauthConfig = {
    // Use PKCE
    oidc: false,
    responseType: 'code',
    issuer: openIdConfig.issuer,
    customTokenParameters: remoteConfig.mode === 'idService' ? ['organizationId', 'token_type'] : undefined,
    customQueryParams: remoteConfig.mode === 'idService' ? {
      require_org: ccAppAuthConfig.requiresOrganizationContext
    } : undefined,
    loginUrl: openIdConfig.authorization_endpoint,
    logoutUrl: openIdConfig.end_session_endpoint,
    tokenEndpoint: openIdConfig.token_endpoint,
    userinfoEndpoint: openIdConfig.userinfo_endpoint,
    redirectUri: window.location.origin,
    clientId: ccAppAuthConfig.clientId,
    scope: scope,
    showDebugInformation: ccAppAuthConfig.ccStage === 'dev',
    // workaround for bug https://github.com/manfredsteyer/angular-oauth2-oidc/issues/1135
    clockSkewInSec: 60
  };
  if (organizationId) {
    oauthConfig.customQueryParams = {
      ...oauthConfig.customQueryParams,
      organization_id: organizationId
    };
  }
  switch (redirectMode) {
    case RedirectMode.CheckSession:
      oauthConfig.customQueryParams = {
        ...oauthConfig.customQueryParams,
        prompt: 'none',
        // TODO check if this is really needed
        signup: 'false'
      };
      break;
    case RedirectMode.ForceLogin:
      oauthConfig.customQueryParams = {
        ...oauthConfig.customQueryParams,
        signup: 'false'
      };
      break;
    default:
      break;
  }
  return oauthConfig;
}
class CCAuthHttpInterceptor {
  constructor(authService, ccAuthConfig) {
    this.authService = authService;
    this.ccAuthConfig = ccAuthConfig;
  }
  intercept(request, next) {
    // dynamically add the correct token, depending on the requested url
    return from(this.addTokenToHeader(request.url, request.headers)).pipe(switchMap(adjustedHeaders => {
      const req = request.clone({
        headers: adjustedHeaders
      });
      return next.handle(req);
    }));
  }
  async addTokenToHeader(url, headers) {
    if (headers.has('Authorization')) {
      // do not modify already existing Authorization header.
      return headers;
    }
    const match = this.ccAuthConfig.authenticatedUrls.find(u => url.startsWith(u));
    if (!match || !this.authService.isAuthenticated()) {
      return headers;
    }
    let token;
    if (this.authService.requiresOrganizationContext() && this.authService.getCurrentMembership()) {
      console.log('add orgMembership token to ' + url);
      token = await this.authService.getCurrentOrgMembershipToken();
    } else {
      console.log('add person token to ' + url);
      token = this.authService.getPersonToken();
    }
    if (token) {
      return headers.set('Authorization', `Bearer ${token}`);
    } else {
      return headers;
    }
  }
  static {
    this.ɵfac = function CCAuthHttpInterceptor_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CCAuthHttpInterceptor)(i0.ɵɵinject(CCAuthService), i0.ɵɵinject(CC_APP_AUTH_CONFIG));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CCAuthHttpInterceptor,
      factory: CCAuthHttpInterceptor.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CCAuthHttpInterceptor, [{
    type: Injectable
  }], () => [{
    type: CCAuthService
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [CC_APP_AUTH_CONFIG]
    }]
  }], null);
})();

/**
 * @returns providers for CustomerPortal authorization autoconfiguration.
 */
function provideCCAuth() {
  return makeEnvironmentProviders([provideOAuthClient(), CCAuthService, {
    provide: HTTP_INTERCEPTORS,
    useClass: CCAuthHttpInterceptor,
    multi: true,
    deps: [CCAuthService, CC_APP_AUTH_CONFIG]
  }, {
    provide: APP_INITIALIZER,
    useFactory: initAppSession,
    multi: true,
    deps: [CCAuthService]
  }]);
}

/**
 * Generated bundle index. Do not edit.
 */

export { CCAuthHttpInterceptor, CCAuthService, CC_APP_AUTH_CONFIG, CC_REMOTE_CONFIG, CC_SESSION_READY_CALLBACK, environmentConfig, initAppSession, provideCCAuth };
