import { Observable } from 'rxjs';

import { RequestMetadata } from '@data-terminal/utils';

import { TimeModeEntry } from '../time-mode-entry.interface';
import { Machine } from '../machine';
import { Activity } from '../operation';

export interface SignOffDialogData {
    data: Machine[];
    unsubmittedTimeEntries: TimeModeEntry[];
    userId: string;
    otherActivities$: Observable<RequestMetadata<Activity[]>>;
}

export interface SignOffDialogConfig {
    workstations: boolean;
    employees: boolean;
    operations: boolean;
    summary: boolean;
}

export enum AssistantsSignOff {
    STAY = 'STAY',
    LEAVE = 'LEAVE',
}

export enum OperationActionSignOff {
    PAUSE = 'PAUSE',
    FINISH = 'FINISH',
}

export interface SignOffBaseStepObject {
    machineId: string;
    isValid: boolean;
}

export interface SignOffEmployees extends SignOffBaseStepObject {
    assistantsSignOff: AssistantsSignOff;
    newCaptain?: string;
}

export interface SignOffOperation extends SignOffBaseStepObject {
    goodAmount: number;
    wasteAmount: number;
    comment: string;
    operationAction: OperationActionSignOff;
    runningOperation: string;
}
