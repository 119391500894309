import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { getFormattedStringTime, timeFormatter } from '../helpers/time-formatter.helper';

@Pipe({
    name: 'timeFormatter',
})
export class TimeFormatterPipe implements PipeTransform {
    transform(
        ms: number,
        hideZerosHours = true,
        hideZerosMinutes = false,
        hideZerosSeconds = false,
        shouldPrefixWithZero = true
    ): string {
        if (ms === undefined || ms === 0) {
            return '-';
        }
        return getFormattedStringTime(
            timeFormatter(ms),
            hideZerosHours,
            hideZerosMinutes,
            hideZerosSeconds,
            shouldPrefixWithZero
        );
    }
}

@NgModule({
    imports: [],
    declarations: [TimeFormatterPipe],
    exports: [TimeFormatterPipe],
})
export class TimeFormatterPipeModule {}
