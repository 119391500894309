import { Entity } from './common';
import { CaptureMode } from './machine';

export interface TimeModeEntry extends Entity {
    email: string;
    shiftStart: string;
    deviceId: string;
    duration: number;
    startTime?: number;
    endTime?: number;
    status: TimeModeStatus;
    jobId?: string;
    activity: string;
    type: CaptureMode;
    goodAmount?: number;
    wasteAmount?: number;
    materials: string[];
    workstepId?: string;
    submissionId: string;
    comment?: string;
}

export enum TimeModeStatus {
    DRAFT = 'Draft',
    PENDING = 'Pending',
    SUBMITTED = 'Submitted',
    ERROR = 'Error',
}
