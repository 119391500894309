import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BACKEND_URL, ORGANIZATION_REQUEST_PREFIX } from '@data-terminal/data-access';
import {
    ApiRequestResponse,
    ApiResponse,
    GLOBAL_SPINNER_HTTP_CONTEXT,
    PreSettingRequest,
    PreSettingRequestResponse,
} from '@data-terminal/utils';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ApiRequestResponseApiService {
    constructor(
        private readonly http: HttpClient,
        @Inject(BACKEND_URL) private readonly backendUrl: string,
        @Inject(ORGANIZATION_REQUEST_PREFIX) private readonly orgRequestPrefix: string
    ) {}

    private readonly API_REQUEST_URL = `${this.backendUrl}${this.orgRequestPrefix}apiRequest`;
    private readonly API_RESPONSE_URL: (requestId: string) => string = (requestId) =>
        `${this.backendUrl}${this.orgRequestPrefix}apiResponse/${requestId}`;
    private readonly PRE_SETTING_REQUEST_URL = `${this.backendUrl}${this.orgRequestPrefix}preSettingRequest`;

    public sendApiReqeust<T>(body: T): Observable<ApiRequestResponse> {
        return this.http.post<ApiRequestResponse>(this.API_REQUEST_URL, body);
    }

    public getApiResponse<V>(requestId: string, globalSpinner = true): Observable<ApiResponse<V>> {
        return this.http.get<ApiResponse<V>>(
            this.API_RESPONSE_URL(requestId),
            globalSpinner
                ? {
                      context: GLOBAL_SPINNER_HTTP_CONTEXT,
                  }
                : undefined
        );
    }

    public sendPreSettingRequest(body: PreSettingRequest): Observable<PreSettingRequestResponse> {
        return this.http.post<PreSettingRequestResponse>(this.PRE_SETTING_REQUEST_URL, body);
    }
}
