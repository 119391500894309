/* eslint-disable  @typescript-eslint/no-explicit-any */
import { EMPTY, Observable, of, timer } from 'rxjs';
import { catchError, expand, map, scan, startWith, switchMap } from 'rxjs/operators';

const DEFAULT_MAX_RETRIES = Infinity;
const DEFAULT_BASE_DELAY_MS = 1000;
const DEFAULT_MAX_DELAY_MS = Infinity;
const DEFAULT_MAX_JITTER_RATIO = 0.2;

export type ResponseError = Error & { status?: number };
export type RetryableReponseError = ResponseError & {
    retryCount: number;
    nextRetryAt: Date | null;
};
export interface RequestMetadata<T, E = ResponseError> {
    isLoading: boolean;
    error?: E;
    data?: T;
}
export type RequestMetadataData<M extends RequestMetadata<any, any>> =
    M extends RequestMetadata<infer T, any> ? T : never;
export type RequestMetadataError<M extends RequestMetadata<any, any>> =
    M extends RequestMetadata<any, infer E> ? E : never;

export interface RetryPolicy {
    maxRetries?: number;
    baseDelayMs?: number;
    maxDelayMs?: number;
    delayFactorFn?: (retryCount: number) => number;
    maxJitterRatio?: number;
}

interface StartWithConfig<T> {
    startWith?: T;
}

export type Config = RetryPolicy & StartWithConfig<any>;

function mapToRequestMetadataInternal<T, E>(
    errorFn: (error: Error) => E,
    startWithData?: T
): (source: Observable<T>) => Observable<RequestMetadata<T, E>> {
    return (source) => {
        return source.pipe(
            map((data) => ({
                data,
                error: undefined,
                isLoading: false,
            })),
            catchError((error: Error) => of({ error: errorFn(error), isLoading: false })),
            startWith({ isLoading: true, data: startWithData })
        );
    };
}

export function mapToRequestMetadata<T>(source: Observable<T>): Observable<RequestMetadata<T>> {
    return source.pipe(
        mapToRequestMetadataInternal((err) => err),
        scan((acc, curr) => ({ ...acc, ...curr }), {} as RequestMetadata<T>)
    );
}

function getNextRetryTime(retryCount: number, policy: RetryPolicy): Date | null {
    const {
        maxRetries = DEFAULT_MAX_RETRIES,
        baseDelayMs = DEFAULT_BASE_DELAY_MS,
        maxDelayMs = DEFAULT_MAX_DELAY_MS,
        delayFactorFn = (n: number) => 2 ** (n - 1),
        maxJitterRatio = DEFAULT_MAX_JITTER_RATIO,
    } = policy;
    // Don't exit early on retryCount >= maxRetries, as retryCount could be NaN
    if (retryCount < maxRetries) {
        const jitterFactor = 1 - maxJitterRatio + Math.random() * maxJitterRatio * 2;
        const delay = Math.min(maxDelayMs, baseDelayMs * delayFactorFn(retryCount + 1) * jitterFactor);
        return new Date(Date.now() + delay);
    }
    return null;
}

function addRetryMetadata(error: Error, policy: RetryPolicy, retryCount: number): RetryableReponseError {
    const newError = error as RetryableReponseError;
    newError.retryCount = retryCount;
    newError.nextRetryAt = getNextRetryTime(retryCount, policy);
    return newError;
}

export function mapToRequestMetadataWithRetry<T>(
    config: Config = {}
): (source: Observable<T>) => Observable<RequestMetadata<T, RetryableReponseError>> {
    return (source: Observable<T>): Observable<RequestMetadata<T, RetryableReponseError>> => {
        return source.pipe(
            mapToRequestMetadataInternal((err) => addRetryMetadata(err, config, 0), config.startWith),
            expand((requestMetadata) => {
                if (requestMetadata.error?.nextRetryAt == null) {
                    return EMPTY;
                }
                const { retryCount, nextRetryAt } = requestMetadata.error;
                return timer(nextRetryAt).pipe(
                    switchMap(() =>
                        source.pipe(
                            mapToRequestMetadataInternal((err) => addRetryMetadata(err, config, retryCount + 1))
                        )
                    )
                );
            }),
            scan((acc, curr) => ({ ...acc, ...curr }), {} as RequestMetadata<T, RetryableReponseError>)
        );
    };
}
