<section class="machine-list hdmui-content hdmui-p-0">
    @if (previousMachines.length > 0 && inDialog) {
        <div class="machine-list__divider hdmui-my-4">
            <h3 class="hdmui-mx-0 hdmui-px-0">
                {{ 'DC.MACHINE_LIST.LAST_USED_MACHINES_DIVIDER' | translate }}
            </h3>
        </div>
    }

    @if (previousMachines.length > 0) {
        <div class="machine-list__entries">
            @for (machine of previousMachines; track identify($index, machine)) {
                <data-terminal-machine-card
                    class="hdmui-m-3"
                    [machine]="machine"
                    [isSelectableMode]="isSelectableMode"
                    [isSelected]="selectionStateObject(machine.machineId).state"
                    [captureMode]="machine.captureMode"
                    (selectCard)="onSelectCard($event)"
                ></data-terminal-machine-card>
            }
        </div>
    }

    @if (inDialog) {
        <div class="machine-list__divider hdmui-my-4">
            <h3 class="hdmui-mx-0 hdmui-px-0">
                {{ 'DC.MACHINE_LIST.MACHINES_DIVIDER' | translate }}
            </h3>
        </div>
    }

    @if (machines.length > 0) {
        <div class="machine-list__entries">
            @for (machine of machines; track identify($index, machine)) {
                <data-terminal-machine-card
                    class="hdmui-m-3"
                    [showOperationInfo]="showOperationInfo"
                    [otherActivities]="otherActivities"
                    [machine]="machine"
                    [isSelectableMode]="isSelectableMode"
                    [isSelected]="selectionStateObject(machine.machineId).state"
                    [captureMode]="machine.captureMode"
                    (selectCard)="onSelectCard($event)"
                ></data-terminal-machine-card>
            }
        </div>
    } @else {
        <div class="empty-states__container">
            <hdmui-empty-states
                [buttonText]="emptyStateButtonText"
                (buttonClick)="onEmptyStateButtonClick()"
                icon="hdmui:exclamationMarkCircled"
            >
                <hdmui-empty-states-title>{{ emptyStateTitle }}</hdmui-empty-states-title>
                <hdmui-empty-states-description>{{ emptyStateDescription }}</hdmui-empty-states-description>
            </hdmui-empty-states>
        </div>
    }
</section>
