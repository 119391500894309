import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class GenericUpdatesService {
    private readonly _selectedOperationUpdate$ = new Subject<string>();

    public selectedOperationUpdate$(): Subject<string> {
        return this._selectedOperationUpdate$;
    }

    public updateOperation(primaryKey: string): void {
        this._selectedOperationUpdate$.next(primaryKey);
    }
}
