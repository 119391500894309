import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivityRequired, OperationNavConfig, OperationTab } from '@data-terminal/shared-models';

const defaultOperationNav = {
    [ActivityRequired.OTHER_ACTIVITY as string]: {
        isDisabled: false,
        badge: 0,
    },
    [ActivityRequired.OPERATION as string]: {
        isDisabled: false,
        badge: 0,
    },
};

@Injectable({
    providedIn: 'root',
})
export class OperationNavigationService {
    private readonly _operationTabsConfig = new BehaviorSubject<OperationNavConfig>(defaultOperationNav);
    public readonly operationTabsConfig$: Observable<OperationNavConfig> = this._operationTabsConfig.asObservable();

    public updateOperationTab(operationTab: OperationTab): void {
        const config: OperationNavConfig = this._operationTabsConfig.value;
        config[ActivityRequired.OPERATION] = operationTab;
        if (operationTab.badge > 0) {
            config[ActivityRequired.OTHER_ACTIVITY].badge = 0;
            config[ActivityRequired.OTHER_ACTIVITY].isDisabled = true;
        } else {
            config[ActivityRequired.OTHER_ACTIVITY].isDisabled = false;
        }
        this._operationTabsConfig.next(config);
    }

    public updateOtherActivityTab(operationTab: OperationTab): void {
        const config: OperationNavConfig = this._operationTabsConfig.value;
        config[ActivityRequired.OTHER_ACTIVITY] = operationTab;
        this._operationTabsConfig.next(config);
    }

    public setInitialState(config: OperationNavConfig): void {
        this._operationTabsConfig.next(config);
    }
}
