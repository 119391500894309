import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { ResponseError } from '../helpers';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'errorFormatter',
})
export class ErrorFormatterPipe implements PipeTransform {
    constructor(private readonly translateService: TranslateService) {}

    public transform(error: ResponseError): string {
        const errorMessage = this.translateService.instant(error.message);
        const details = this.getErrorDetails(error);
        return `${errorMessage} ${details}`.trim();
    }

    private getErrorDetails(error: ResponseError): string {
        const details = [error.name, error.status]
            .filter((str) => !!str)
            .map((value) => `${value}`)
            .join(': ');
        return details !== '' ? `(${details})` : '';
    }
}

@NgModule({
    imports: [TranslateModule],
    declarations: [ErrorFormatterPipe],
    exports: [ErrorFormatterPipe],
})
export class ErrorFormatterPipeModule {}
