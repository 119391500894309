import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const requireOneControl: (firstControlName: string, secondControlName: string) => ValidatorFn = (
    firstControlName,
    secondControlName
) => {
    return (formGroup: AbstractControl): ValidationErrors | null => {
        if (
            (formGroup.get(firstControlName)?.value === '' || formGroup.get(firstControlName)?.value === null) &&
            (formGroup.get(secondControlName)?.value === '' || formGroup.get(secondControlName)?.value === null)
        ) {
            formGroup.get(firstControlName)?.setErrors({ required: true });
            formGroup.get(secondControlName)?.setErrors({ required: true });
            return { required: true };
        }

        formGroup.get(firstControlName)?.setErrors(null);
        formGroup.get(secondControlName)?.setErrors(null);
        return null;
    };
};
