import { CCOrganization, CCPerson } from '@heidelberg/control-center-frontend-integration/cc';

const TIMESTAMP5 = '32eba072-32b1-43d2-9c60-9ac6222ff913';
const TIMESTAMP4 = 'pers_ed935185-f046-46ea-8600-80560c9fc17e';
const TIMESTAMP3 = '2021-10-15T15:24:51.434Z';
const TIMESTAMP2 = '2021-10-15T15:24:51.375Z';
const TIMESTAMP1 = '2021-10-15T15:24:51.354Z';

export const personSessionStub: CCPerson = {
    confirmedGdprVersion: '',
    confirmedTermsVersion: '',
    zPlatformMember: false,
    id: TIMESTAMP4,
    createdAt: '2021-09-17T07:49:34.692Z',
    updatedAt: '2021-10-15T15:24:51.015Z',
    status: 'Active',
    name: 'Garbaciak',
    countryCode: 'PL',
    currencyCode: 'PLN',
    avatarUrl: '',
    email: 'mateusz.garbaciak@niceproject.eu',
    emailConfirmed: true,
    fullName: 'Mateusz Garbaciak',
    givenName: 'Mateusz',
    locale: 'en-US',
    timezone: 'Etc/UTC',
    twoFactorAuthenticationEnabled: false,
    unitSystem: 'metric',
    zPersonId: TIMESTAMP5,
    zCreatedAt: '2021-07-05T11:07:50.199Z',
    zUpdatedAt: '2021-10-12T12:13:09.853Z',
    memberships: [
        {
            id: 'orgms_bab51de3-6bf0-482b-8731-54f96c4055f8',
            createdAt: '2021-09-17T07:49:34.813Z',
            updatedAt: TIMESTAMP3,
            organizationId: 'org_31c67cb6-9614-46cb-846b-76f7d12ca17d',
            zOrganizationId: 'a2f6c86f-eecf-4329-a3cd-a7e55ae77d45',
            zMembershipId: '9f4ce8ba-c098-43a7-b57d-18e7faf58262',
            personId: TIMESTAMP4,
            zPersonId: TIMESTAMP5,
            zRoles: ['admin'],
            zCreatedAt: '2021-07-16T08:58:18.864Z',
            zUpdatedAt: '2021-07-16T08:58:18.864Z',
            organization: {
                id: 'org_31c67cb6-9614-46cb-846b-76f7d12ca17d',
                createdAt: '2021-07-19T15:58:12.586Z',
                updatedAt: TIMESTAMP3,
                zOrganizationId: 'a2f6c86f-eecf-4329-a3cd-a7e55ae77d45',
                zPlatformMember: true,
                status: CCOrganization.StatusEnum.unboarded,
                zStatus: 'Connected',
                name: 'co-creation Partner GmbH (STG)',
                countryCode: 'DE',
                currencyCode: 'EUR',
                kinds: ['printer'],
                logoUrl: `https://hub.sandbox.zaikio.com/rails/active_storage/
                    blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWs0T
                    kRZeE1UQXpNeTFsWkRjM0xUUTFNMk10T0RRM09DMDFPVGt3TkRnMFpqazJO
                    allHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--9b
                    dcf0185cb04d156759e404b2987929aa577d01/Tux.svg.png`,
                sections: { printer: [] },
                ssus: [],
            },
            ccRoles: ['adm'],
        },
        {
            id: 'orgms_d81f139e-914b-4d19-8f47-e61e14e4595b',
            createdAt: '2021-10-01T09:46:55.664Z',
            updatedAt: TIMESTAMP2,
            organizationId: 'org_e858ad3d-6195-40b5-b535-28dbc3559515',
            zOrganizationId: '10d8e6d2-8819-4da0-9ccc-b4e3ba2f69f9',
            zMembershipId: '630c82f9-f61c-44dd-a8c8-fe0342c9a6b7',
            personId: TIMESTAMP4,
            zPersonId: '630c82f9-f61c-44dd-a8c8-fe0342c9a6b7',
            ccRoles: ['own'],
            zCreatedAt: '2021-07-06T12:24:16.057Z',
            zUpdatedAt: '2021-07-06T12:24:16.057Z',
            organization: {
                id: 'org_e858ad3d-6195-40b5-b535-28dbc3559515',
                createdAt: '2021-10-01T09:46:55.664Z',
                updatedAt: TIMESTAMP2,
                zOrganizationId: '10d8e6d2-8819-4da0-9ccc-b4e3ba2f69f9',
                zPlatformMember: false,
                status: CCOrganization.StatusEnum.unboarded,
                zStatus: 'NotConnected',
                name: 'MyTestOrg',
                countryCode: 'PL',
                currencyCode: 'PLN',
                kinds: ['printer'],
                logoUrl: 'null',
                sections: { printer: [] },
                ssus: [],
            },
        },
        {
            id: 'orgms_5690b438-8940-4459-be8a-a02586e16d1f',
            createdAt: '2021-09-27T13:26:09.735Z',
            updatedAt: TIMESTAMP1,
            organizationId: 'org_4db7ec95-604c-46d5-853a-94855f6228bb',
            zOrganizationId: '995de8cd-bafa-4be9-9648-8ab42d94df7e',
            zMembershipId: 'c39856d5-a64a-4cc2-8c9a-7eabaadfb1c7',
            personId: TIMESTAMP4,
            zPersonId: TIMESTAMP5,
            ccRoles: ['mem'],
            zCreatedAt: '2021-07-26T05:21:37.993Z',
            zUpdatedAt: '2021-07-26T05:21:37.993Z',
            organization: {
                id: 'org_4db7ec95-604c-46d5-853a-94855f6228bb',
                createdAt: '2021-07-12T15:01:14.558Z',
                updatedAt: TIMESTAMP1,
                zOrganizationId: '995de8cd-bafa-4be9-9648-8ab42d94df7e',
                zPlatformMember: true,
                status: CCOrganization.StatusEnum.unboarded,
                zStatus: 'Connected',
                name: 'DataTerminal Demo Systems Inc.',
                countryCode: 'DE',
                currencyCode: 'EUR',
                kinds: ['printer'],
                logoUrl: ` https://hub.sandbox.zaikio.com/rails/active_storage/blobs/
                    redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWxoTlRVM09USmpZaTB3WX
                    poa0xUUm1aV0l0T0RNeFpDMDROemN4TWpWaFlqWXpOemdHT2daRlZBPT0iLCJleHAiOm51b
                    GwsInB1ciI6ImJsb2JfaWQifX0=--7361f283ac4ef9121f9e28598bc9b05085da1800
                    /2021-06-18%2013_00_06-Window.png`,
                sections: { printer: [] },
                ssus: [],
            },
        },
    ],
};
