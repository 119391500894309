import { inject, InjectionToken } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export const THOUSAND_SEPARATOR = new InjectionToken<string>('Thousand separator value which depends on set locale', {
    factory: (): string => {
        const translate = inject(TranslateService);
        const locale = translate.currentLang;
        const numberWithGroupSeparator = 1000;

        return (
            Intl.NumberFormat(locale)
                .formatToParts(numberWithGroupSeparator)
                .find((part) => part.type === 'group')
                ?.value.trim() || ' '
        );
    },
});
