import {
    HttpContext,
    HttpContextToken,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';

import { LoadingStateAction, LoadingStateService } from '@data-terminal/utils';

const DEFAULT_TOKEN_VALUE: () => boolean = () => false;

export const GLOBAL_SPINNER_HTTP_TOKEN = new HttpContextToken<boolean>(DEFAULT_TOKEN_VALUE);

export const GLOBAL_SPINNER_HTTP_CONTEXT: HttpContext = new HttpContext().set(GLOBAL_SPINNER_HTTP_TOKEN, true);

@Injectable()
export class LoadingSpinnerInterceptor implements HttpInterceptor {
    constructor(private readonly loadingStateService: LoadingStateService) {}

    // eslint-disable-next-line
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.context.get(GLOBAL_SPINNER_HTTP_TOKEN)) {
            this.loadingStateService.handleRequest(LoadingStateAction.ADD);
            return next.handle(request).pipe(
                finalize(() => {
                    this.loadingStateService.handleRequest(LoadingStateAction.REMOVE);
                })
            );
        } else {
            return next.handle(request);
        }
    }
}
