import { InjectionToken } from '@angular/core';
import { environment } from '../../../../../src/environments/environment';

export const ORG_ID_TEMPLATE = '[data-terminal-orgId]';

export const ORGANIZATION_REQUEST_PREFIX = new InjectionToken<string>(
    'Wrapper around environment.ORGANIZATION_REQUEST_PREFIX',
    {
        providedIn: 'root',
        factory: () => `${environment.ORGANIZATION_REQUEST_PREFIX}/${ORG_ID_TEMPLATE}/`,
    }
);
