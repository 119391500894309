import { inject, InjectionToken } from '@angular/core';
import { WINDOW } from './window.injection-token';

export const LOCATION = new InjectionToken<Location>('Wrapper around Window`s location object', {
    providedIn: 'root',
    factory: () => {
        const window = inject(WINDOW);
        return window.location;
    },
});
