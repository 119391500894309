import { Component, Input, NgModule, ViewEncapsulation } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'data-terminal-loading-indicator',
    templateUrl: './loading-indicator.component.html',
    styleUrls: ['./loading-indicator.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LoadingIndicatorComponent {
    @Input() label?: string;
}
@NgModule({
    imports: [MatProgressSpinnerModule],
    declarations: [LoadingIndicatorComponent],
    exports: [LoadingIndicatorComponent],
})
export class LoadingIndicatorComponentModule {}
