import { Component, Input, NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'data-terminal-custom-divider',
    templateUrl: './custom-divider.component.html',
    styleUrls: ['./custom-divider.component.scss'],
})
export class CustomDividerComponent {
    @Input() public text?: string;
    @Input() public textClass?: string;
}

@NgModule({
    declarations: [CustomDividerComponent],
    exports: [CustomDividerComponent],
    imports: [MatDividerModule, CommonModule],
})
export class CustomDividerModule {}
