import { Injectable } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { filter, map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ActivatedRouteDataService {
    public readonly activatedRouteData$: Observable<Data>;

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router
    ) {
        this.activatedRouteData$ = this.router.events.pipe(
            filter((e) => e instanceof NavigationEnd),
            map(() => this.getLatestActivatedRouteData()),
            shareReplay(1)
        );
    }

    private getLatestActivatedRouteData(): Data {
        let activatedRoute = this.activatedRoute;
        while (activatedRoute.firstChild) {
            activatedRoute = activatedRoute.firstChild;
        }
        return activatedRoute.snapshot.data;
    }
}
