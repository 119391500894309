<mat-form-field class="hdmui-mx-2">
    <mat-icon matPrefix svgIcon="hdmui:search"></mat-icon>
    <input
        #searchInput
        matInput
        [(ngModel)]="searchPhrase"
        (keyup)="applyInput()"
        [placeholder]="'INPUT.SEARCH' | translate"
        [matAutocomplete]="auto"
    />
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="applyInput()">
        @for (suggestion of autoCompleteSuggestions$ | async; track suggestion) {
            <mat-option [value]="suggestion">
                {{ suggestion }}
            </mat-option>
        }
        @if (autoCompleteCaption.length > 0) {
            <mat-divider [vertical]="false"></mat-divider>
            <p class="hdmui-caption">{{ autoCompleteCaption }}</p>
        }
    </mat-autocomplete>
    @if (searchPhrase.length > 0) {
        <button (click)="onResetClick()" aria-label="Clear" mat-icon-button matSuffix>
            <mat-icon svgIcon="hdmui:xSmall"></mat-icon>
        </button>
    } @else if (barcodeAllowed) {
        <button (click)="onBarcodeClick()" mat-icon-button matSuffix>
            <mat-icon class="barcode__icon" svgIcon="hdmui:rteAlignmentJustify"></mat-icon>
        </button>
    }
</mat-form-field>
