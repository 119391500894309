/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { isNullOrUndefined, RequestMetadata } from '@data-terminal/utils';

@Directive({
    selector: '[isDataPresent]', // eslint-disable-line
})
export class IsDataPresentDirective {
    constructor(
        private readonly templateRef: TemplateRef<any>,
        private readonly viewContainer: ViewContainerRef
    ) {}

    @Input()
    public set isDataPresent(metadata: RequestMetadata<any>) {
        if (!metadata.error && !isNullOrUndefined(metadata.data)) {
            if (this.viewContainer.length === 0) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
        } else {
            this.viewContainer.clear();
        }
    }
}
