<div class="data-terminal-error-message">
    <mat-icon class="data-terminal-error-message__icon" svgIcon="hdmui:warning"></mat-icon>
    @if (title) {
        <div class="data-terminal-error-message__title">
            <h2>{{ title }}</h2>
        </div>
    }
    @if (description) {
        <div class="data-terminal-error-message__description">
            <p>{{ description }}</p>
        </div>
    }
</div>
