import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, fromEvent, startWith } from 'rxjs';
const DEBOUNCE_TIME_500 = 500;

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class ResizeService {
    constructor(private readonly breakpointObserver: BreakpointObserver) {}

    public isTablet$ = this.breakpointObserver.observe('(max-width: 1600px)').pipe(
        untilDestroyed(this),
        map((result) => result.matches)
    );

    public isDesktopLarge$ = this.breakpointObserver.observe('(min-width: 1600px) and (max-width: 2000px)').pipe(
        untilDestroyed(this),
        map((result) => result.matches)
    );

    public isDesktopSuperLarge$ = this.breakpointObserver.observe('(min-width: 2000px)').pipe(
        untilDestroyed(this),
        map((result) => result.matches)
    );

    public windowSize$ = fromEvent(window, 'resize')
        .pipe(untilDestroyed(this), startWith(window.innerWidth), debounceTime(DEBOUNCE_TIME_500))
        .pipe(map(() => window.innerWidth));

    public getNewResizeObserver(callback: (entries: ResizeObserverEntry[]) => void): ResizeObserver {
        return new ResizeObserver(callback);
    }
}
