import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BACKEND_URL, ORGANIZATION_REQUEST_PREFIX } from '@data-terminal/data-access';
import { ErrorCorrection } from '@data-terminal/shared-models';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ErrorCorrectionApiService {
    constructor(
        private readonly http: HttpClient,
        @Inject(BACKEND_URL) private readonly backendUrl: string,
        @Inject(ORGANIZATION_REQUEST_PREFIX) private readonly orgRequestPrefix: string
    ) {}

    private readonly DO_ERROR_CORRECTION_URL = `${this.backendUrl}${this.orgRequestPrefix}doErrorCorrection`;

    public doErrorCorrection(body: ErrorCorrection): Observable<boolean> {
        return this.http.post<boolean>(this.DO_ERROR_CORRECTION_URL, body);
    }
}
