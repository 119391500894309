import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CCAuthService } from '@heidelberg/control-center-frontend-integration/auth';

import { BACKEND_URL, ORGANIZATION_REQUEST_PREFIX } from '@data-terminal/data-access';
import { CloudSession } from '@data-terminal/shared-models';

@Injectable({
    providedIn: 'root',
})
export class CloudSessionService {
    private readonly LATEST_SESSION_URL: (userId: string) => string = (userId: string) =>
        `${this.backendUrl}${this.orgRequestPrefix}management/${userId}/latest`;

    private readonly UPDATE_SESSION_URL: (userId: string) => string = (userId: string) =>
        `${this.backendUrl}${this.orgRequestPrefix}management/${userId}/update`;

    private readonly DELETE_SESSION_URL: (userId: string) => string = (userId: string) =>
        `${this.backendUrl}${this.orgRequestPrefix}management/${userId}/delete`;

    private _session!: CloudSession;

    constructor(
        @Inject(BACKEND_URL) private readonly backendUrl: string,
        @Inject(ORGANIZATION_REQUEST_PREFIX) private readonly orgRequestPrefix: string,
        private readonly http: HttpClient,
        private readonly ccAuthService: CCAuthService
    ) {}

    public getLatestUserSession(): Observable<CloudSession> {
        return this.http
            .get<CloudSession>(this.LATEST_SESSION_URL(this.ccAuthService.getCurrentUser()?.email || ''))
            .pipe(tap(this.updateSessionObject));
    }

    public getSessionData(): CloudSession {
        return this._session;
    }

    public updateMachines(machines: string[]): Observable<CloudSession> {
        return this.updateSession(
            { lastRoute: this._session.lastRoute, machines },
            this.ccAuthService.getCurrentUser()?.email || ''
        );
    }

    public updateLastRoute(lastRoute: string): Observable<CloudSession> {
        return this.updateSession(
            { machines: this._session.machines, lastRoute },
            this.ccAuthService.getCurrentUser()?.email || ''
        );
    }

    // TODO: call the function somewhere
    public deleteSession(): Observable<CloudSession> {
        return this.http
            .delete<CloudSession>(this.DELETE_SESSION_URL(this.ccAuthService.getCurrentUser()?.email || ''))
            .pipe(tap(this.updateSessionObject));
    }

    private updateSession(body: CloudSession, userId: string): Observable<CloudSession> {
        return this.http.post<CloudSession>(this.UPDATE_SESSION_URL(userId), body).pipe(tap(this.updateSessionObject));
    }

    private readonly updateSessionObject = (session: CloudSession): void => {
        this._session = session;
    };
}
