import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CloudToolbarBreadcrumb } from '@heidelberg/hdmui-angular';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { ActivatedRouteDataService } from '@data-terminal/data-access';
import { ROUTE_PARAMS } from '@data-terminal/shared-models';
import { getParams } from '../helpers';

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbsService {
    private readonly _routeParamsMap = new Map<string, string>();
    private readonly _breadcrumbs$: BehaviorSubject<CloudToolbarBreadcrumb[]> = new BehaviorSubject<
        CloudToolbarBreadcrumb[]
    >([]);
    public breadcrumbs$ = this._breadcrumbs$.asObservable();

    constructor(
        private readonly translateService: TranslateService,
        private readonly activatedRouteDataService: ActivatedRouteDataService,
        private readonly activatedRoute: ActivatedRoute
    ) {
        this.breadcrumbs$ = activatedRouteDataService.activatedRouteData$.pipe(
            map((data) => {
                const breadcrumbsCopy = this.createCopy(data.breadcrumbs);
                return this.createCloudToolbarBreadcrumbs(breadcrumbsCopy, getParams(this.activatedRoute));
            })
        );
    }

    public hasParam(key: string): boolean {
        return this._routeParamsMap.has(key);
    }

    public getParam(key: string): string | undefined {
        return this._routeParamsMap.get(key);
    }

    public saveParam(key: string, value: string): void {
        this._routeParamsMap.set(key, value);
    }

    private createCopy(breadcrumbs: CloudToolbarBreadcrumb[] | undefined): CloudToolbarBreadcrumb[] {
        if (!breadcrumbs) {
            return [];
        }
        return breadcrumbs.map((breadcrumb) => ({
            text: breadcrumb.text,
            routerLink: [...(breadcrumb.routerLink || [])],
        }));
    }

    private createCloudToolbarBreadcrumbs(
        breadcrumbs: CloudToolbarBreadcrumb[],
        params: { [key: string]: string }
    ): CloudToolbarBreadcrumb[] {
        breadcrumbs = this.replaceBreadcrumbsText(breadcrumbs);
        return this.replaceRouteParams(breadcrumbs, params);
    }

    private replaceBreadcrumbsText(breadcrumbs: CloudToolbarBreadcrumb[]): CloudToolbarBreadcrumb[] {
        this.replaceDynamicName(breadcrumbs, ROUTE_PARAMS.machineId);
        this.replaceDynamicName(breadcrumbs, ROUTE_PARAMS.primaryKey);
        this.translateBreadcrumbText(breadcrumbs);
        return breadcrumbs;
    }

    private replaceDynamicName(breadcrumbs: CloudToolbarBreadcrumb[], routeParam: string): void {
        const breadcrumb = breadcrumbs.find((e) => e.text === routeParam);
        if (breadcrumb) {
            breadcrumb.text = this._routeParamsMap.get(routeParam) || breadcrumb.text;
        }
    }

    private translateBreadcrumbText(breadcrumbs: CloudToolbarBreadcrumb[]): void {
        breadcrumbs.forEach((el) => (el.text = this.translateService.instant(el.text)));
    }

    private replaceRouteParams(
        breadcrumbs: CloudToolbarBreadcrumb[],
        params: { [key: string]: string }
    ): CloudToolbarBreadcrumb[] {
        return breadcrumbs.map((breadcrumb) => ({
            ...breadcrumb,
            routerLink:
                breadcrumb.routerLink?.length === 0
                    ? undefined
                    : breadcrumb.routerLink?.map((segment) => {
                          if (params[segment]) {
                              return params[segment];
                          }
                          return segment;
                      }),
        }));
    }
}
