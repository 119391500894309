import {
    AfterContentInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgModule,
    Output,
    ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDividerModule } from '@angular/material/divider';

@UntilDestroy()
@Component({
    selector: 'data-terminal-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent implements AfterContentInit {
    @ViewChild('searchInput', { read: ElementRef }) input!: ElementRef<HTMLInputElement>;

    @Input()
    public sessionStorageKey?: string;

    @Input()
    searchPhrase = '';

    @Input()
    barcodeAllowed = true;

    @Input()
    autoCompleteSuggestionsPool: string[] = [];

    @Input()
    autoCompleteCaption = '';

    @Output()
    searchPhraseChange = new EventEmitter<string>();

    @Output()
    barcodeClick = new EventEmitter<void>();

    public autoCompleteSuggestions$: Observable<string[]> = of([]);

    ngAfterContentInit(): void {
        if (this.sessionStorageKey) {
            this.searchPhrase = sessionStorage.getItem(this.sessionStorageKey) || '';
            this.applyInput();
        }
    }

    public focus(): void {
        this.input.nativeElement.focus();
    }

    public applyInput(): void {
        this.searchPhraseChange.emit(this.searchPhrase);
        this.sessionStorageKey && sessionStorage.setItem(this.sessionStorageKey, this.searchPhrase);
        if (this.searchPhrase.length > 1) {
            this.autoCompleteSuggestions$ = of(
                this.autoCompleteSuggestionsPool.filter((ele) =>
                    ele.toLowerCase().startsWith(this.searchPhrase.toLowerCase())
                )
            );
        } else {
            this.autoCompleteSuggestions$ = of([]);
        }
    }

    public onResetClick(): void {
        this.searchPhrase = '';
        this.applyInput();
    }

    public onBarcodeClick(): void {
        this.barcodeClick.emit();
    }
}

@NgModule({
    declarations: [SearchInputComponent],
    exports: [SearchInputComponent],
    imports: [
        MatIconModule,
        TranslateModule,
        MatInputModule,
        CommonModule,
        MatButtonModule,
        FormsModule,
        MatAutocompleteModule,
        MatDividerModule,
    ],
})
export class SearchInputModule {}
