export enum UnitType {
    LENGTH = 'LENGTH',
    GRAMMAGE = 'GRAMMAGE',
}

export enum MetricUnits {
    LENGTH = 'mm',
    GRAMMAGE = 'g/m2',
}

export enum ImperialUnits {
    LENGTH = 'inches',
    GRAMMAGE = 'g/m2',
}
