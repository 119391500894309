import { CaptureMode, MachineClass, Role, UserMachines } from '@data-terminal/shared-models';

export const userMachinesMock: UserMachines = {
    availableAssistants: [],
    assignedMachines: [],
    preselectedMachines: [],
    allMachines: [
        {
            id: '1234567',
            machineName: 'MU-01',
            connectType: 'ID_KIND_OF_CONNECTION_DT',
            machineClass: MachineClass.ID_SHEETFOLDER,
            defSetupActivityId: 'ID36feb80d282843208f131af9fa478b95',
            defProdActivity: '',
            lastKeepAlive: 1,
            organizationId: '',
            createdAt: '',
            defAssistantActivityId: '',
            updatedAt: '',
            isCounterBox: false,
            captureMode: CaptureMode.START_STOP,
            machineId: '1234567',
            signedOnUsers: [
                {
                    firstName: 'Bennet',
                    lastName: 'Horn',
                    userId: '1',
                    role: Role.ASSISTANT,
                    userMachinePermissions: [],
                    captain: false,
                },
                {
                    firstName: 'Kacper',
                    lastName: 'test',
                    userId: '1',
                    role: Role.ASSISTANT,
                    userMachinePermissions: [],
                    captain: false,
                },
                {
                    firstName: 'Kacper',
                    lastName: 'test',
                    userId: 'kacper.test@niceproject.eu',
                    role: Role.OPERATOR,
                    userMachinePermissions: [],
                    captain: true,
                },
            ],
            runningOperation: {
                primaryKey: '123345__rw_220713_070032854_001014',
                activityName: 'TestActivityName',
            },
            lastActiveDate: 100000000,
        },
        {
            id: '3000',
            machineName: 'DT_POLAR',
            connectType: 'ID_KIND_OF_CONNECTION_DT',
            machineClass: MachineClass.ID_CUTTER,
            defSetupActivityId: 'ID36feb80d282843208f131af9fa478b95',
            defProdActivity: '',
            lastKeepAlive: 1,
            organizationId: '',
            createdAt: '',
            defAssistantActivityId: '',
            updatedAt: '',
            isCounterBox: false,
            captureMode: CaptureMode.START_STOP,
            machineId: '3000',
            signedOnUsers: [],
            lastActiveDate: 100000000,
        },
        {
            id: '3002',
            machineName: 'IL_POLAR',
            connectType: 'ID_KIND_OF_CONNECTION_DT',
            machineClass: MachineClass.ID_CASEMAKER,
            defSetupActivityId: 'ID36feb80d282843208f131af9fa478b95',
            defProdActivity: '',
            lastKeepAlive: 1,
            organizationId: '',
            createdAt: '',
            defAssistantActivityId: '',
            updatedAt: '',
            isCounterBox: true,
            captureMode: CaptureMode.START_STOP,
            machineId: '3002',
            signedOnUsers: [],
            lastActiveDate: 100000000,
        },
        {
            id: '5010',
            machineName: 'DT_FALZ1',
            connectType: 'ID_KIND_OF_CONNECTION_DT',
            machineClass: MachineClass.ID_SHEETFOLDER,
            defSetupActivityId: 'ID36feb80d282843208f131af9fa478b95',
            defProdActivity: '',
            lastKeepAlive: 1,
            organizationId: '',
            createdAt: '',
            defAssistantActivityId: '',
            updatedAt: '',
            machineId: '5010',
            isCounterBox: false,
            captureMode: CaptureMode.START_STOP,
            signedOnUsers: [
                {
                    firstName: 'Jon',
                    lastName: 'Doe',
                    userId: 'DoeJ',
                    role: Role.OPERATOR,
                    userMachinePermissions: [],
                    captain: true,
                },
                {
                    firstName: 'Max',
                    lastName: 'Mustermann',
                    userId: 'MustermannM',
                    role: Role.ASSISTANT,
                    userMachinePermissions: [],
                    captain: false,
                },
            ],
            lastActiveDate: 100000000,
        },
        {
            id: '5300',
            machineName: 'DT_ST350',
            connectType: 'ID_KIND_OF_CONNECTION_DT',
            machineClass: MachineClass.ID_STITCHER,
            defSetupActivityId: 'ID36feb80d282843208f131af9fa478b95',
            defProdActivity: '',
            lastKeepAlive: 1,
            organizationId: '',
            createdAt: '',
            defAssistantActivityId: '',
            updatedAt: '',
            isCounterBox: false,
            captureMode: CaptureMode.START_STOP,
            machineId: '5300',
            signedOnUsers: [
                {
                    firstName: 'Hans-Peter',
                    lastName: 'Schmitt',
                    userId: 'SchmittH',
                    role: Role.OPERATOR,
                    userMachinePermissions: [],
                    captain: true,
                },
            ],
            lastActiveDate: 100000000,
        },
        {
            id: '6230',
            machineName: 'TA52',
            connectType: 'ID_KIND_OF_CONNECTION_DT',
            machineClass: MachineClass.ID_ADHESIVEBINDER,
            defSetupActivityId: 'ID36feb80d282843208f131af9fa478b95',
            defProdActivity: '',
            lastKeepAlive: 1,
            organizationId: '',
            createdAt: '',
            defAssistantActivityId: '',
            updatedAt: '',
            isCounterBox: false,
            captureMode: CaptureMode.START_STOP,
            machineId: '6230',
            signedOnUsers: [],
            lastActiveDate: 100000000,
        },
    ],
    serviceError: { error: false, errorId: '', errorMsg: 'ok' },
};
