import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CCAuthService } from '@heidelberg/control-center-frontend-integration/auth';

import { ORG_ID_TEMPLATE, ORGANIZATION_REQUEST_PREFIX } from '@data-terminal/data-access';

@Injectable()
export class OrganizationUrlInterceptor implements HttpInterceptor {
    constructor(
        private readonly ccAuthService: CCAuthService,
        @Inject(ORGANIZATION_REQUEST_PREFIX) private readonly orgRequestPrefix: string
    ) {}

    // eslint-disable-next-line
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const orgId = this.ccAuthService.getCurrentMembership()?.organizationId;

        if (request.url.indexOf(this.orgRequestPrefix) !== -1 && orgId) {
            const adjustedRequest = request.clone({
                url: request.url.replace(ORG_ID_TEMPLATE, orgId),
            });

            return next.handle(adjustedRequest);
        }

        return next.handle(request);
    }
}
