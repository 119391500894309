@if (!isSelectableMode) {
    <a
        [routerLink]="
            captureMode === CaptureMode.TIME
                ? './' + machine.machineId + '/' + RoutePath.timeLogging
                : './' + machine.machineId + '/' + RoutePath.operations
        "
        [state]="{ workstation: { name: machine.machineName, machineClass: machine.machineClass } }"
    >
        <ng-container *ngTemplateOutlet="machineCard"></ng-container>
    </a>
} @else {
    <ng-container *ngTemplateOutlet="machineCard"></ng-container>
}

<ng-template #machineCard>
    <mat-card
        appearance="outlined"
        class="machine hdmui-p-0"
        [ngClass]="{ selected: isSelected }"
        (click)="onSelectCard()"
    >
        <div class="machine__image">
            <mat-icon class="machine__image--icon" [svgIcon]="machine.machineClass | machineIcon"></mat-icon>
            @if (isSelectableMode) {
                <button mat-icon-button class="machine__select-button">
                    <mat-icon
                        [ngClass]="{ selected__button: isSelected }"
                        svgIcon="{{ isSelected ? 'hdmui:hookCircledFilled' : 'hdmui:plusCircledFilled' }}"
                    ></mat-icon>
                </button>
            }
        </div>
        <div class="machine__infos hdmui-mb-3">
            <div class="machine__infos__machine">
                <h1
                    class="hdmui-font-serif hdmui-mb-0 machine__info-text"
                    [matTooltip]="machine.machineName"
                    matTooltipPosition="above"
                >
                    {{ machine.machineName }}
                </h1>
                <h1 class="hdmui-font-bold hdmui-my-0" [matTooltip]="machine.machineId" matTooltipPosition="above">
                    {{ machine.machineId }}
                </h1>
            </div>
            <div class="machine__infos__users">
                <hdmui-avatar
                    class="hdmui-avatar-small"
                    [fullName]="operatorEmail || ''"
                    [matTooltip]="operatorEmail || ''"
                    matTooltipPosition="above"
                ></hdmui-avatar>
                @if (assistants.length > 0) {
                    <p class="hdmui-my-0">
                        {{ '+' + assistants.length }}
                    </p>
                }
            </div>
            @if (showOperationInfo) {
                <div
                    class="machine__infos__operation fixed-label-size"
                    [class.hidden]="machine.captureMode === CaptureMode.TIME"
                >
                    <hdmui-key-value
                        [matTooltip]="machineCardItem.operation!"
                        matTooltipPosition="above"
                        [label]="'DC.MACHINE_CARD.OPERATION' | translate"
                        >{{ machineCardItem.operation || '-' | translate }}
                    </hdmui-key-value>
                    <hdmui-key-value
                        [label]="'DC.MACHINE_CARD.ACTIVITY' | translate"
                        [matTooltip]="machineCardItem.activity!"
                        matTooltipPosition="above"
                        >{{ machineCardItem.activity || 'Idle' }}
                    </hdmui-key-value>
                </div>
            }
        </div>
        <mat-progress-bar
            mode="determinate"
            class="machine__progress-bar"
            [ngClass]="{
                'machine__progress-bar__completed': isCompleted(),
            }"
            [value]="machine.runningOperation?.progressPercent || 0"
            style="height: 5px"
        ></mat-progress-bar>
    </mat-card>
</ng-template>
