import { CommonModule } from '@angular/common';
import { Component, NgModule, ViewEncapsulation } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'data-terminal-sign-in-connect-indicator',
    templateUrl: './sign-in-connect-indicator.component.html',
    styleUrls: ['./sign-in-connect-indicator.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SignInConnectIndicatorComponent {}
@NgModule({
    imports: [CommonModule, MatProgressBarModule, MatIconModule, TranslateModule.forChild()],
    declarations: [SignInConnectIndicatorComponent],
    exports: [SignInConnectIndicatorComponent],
})
export class SignInConnectIndicatorComponentModule {}
