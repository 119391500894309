import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CCNotificationPriority, CCTextType } from '@heidelberg/control-center-frontend-integration';

import { NotificationSendingApiService } from '@data-terminal/data-access';

import { DCNotificationRequest, DCNotificationResponse } from 'projects/shared-models/src/lib/notification.';

@Injectable({
    providedIn: 'root',
})
export class NotificationSendingService {
    constructor(private readonly notificationApiService: NotificationSendingApiService) {}

    public sendGeneralNotification(body: DCNotificationRequest): Observable<DCNotificationResponse> {
        return this.notificationApiService.sendNotificationEvent(body);
    }

    public sendUserSignOffLeaveNotification(
        operator: string,
        machineId: string,
        userList: string[]
    ): Observable<DCNotificationResponse> {
        const body = {
            priority: CCNotificationPriority.info,
            recipients: userList,
            titleKey: 'DC.NOTIFICATION.SIGNOFFBYOPERATOR.TITLE',
            messageKey: 'DC.NOTIFICATION.SIGNOFFBYOPERATOR',
            messageArgs: {
                operator: {
                    textType: CCTextType.plain,
                    value: operator,
                },
                machineId: {
                    textType: CCTextType.plain,
                    value: machineId,
                },
            },
        } as DCNotificationRequest;
        return this.sendGeneralNotification(body);
    }
}
