import { Component, Input, OnInit } from '@angular/core';
import { HDMUI_THEMES, ThemeService } from '@heidelberg/hdmui-angular';

export type LogoType = 'ICON' | 'TEXT';

@Component({
    standalone: true,
    selector: 'data-terminal-heidelberg-logo',
    templateUrl: './heidelberg-logo.component.html',
    styleUrls: ['./heidelberg-logo.component.scss'],
})
export class HeidelbergLogoComponent implements OnInit {
    @Input()
    type: LogoType = 'TEXT';

    public readonly IMG_ALT_ATTR: string = 'Heidelberg Logo';

    public url!: string;

    private readonly theme: HDMUI_THEMES | '';

    constructor(private readonly themeService: ThemeService) {
        this.theme = themeService.currentTheme;
    }

    ngOnInit(): void {
        switch (this.type) {
            case 'TEXT':
                this.url = this.buildUrl(this.textLogoName);
                break;
            case 'ICON':
                this.url = this.buildUrl(this.iconLogoName);
                break;
            default:
        }
    }

    get textLogoName(): string {
        switch (this.theme) {
            case HDMUI_THEMES.LIGHT_THEME_CLASS:
                return 'LOGO-HEIDELBERG-MULTICOLOR-NO-GRADIENT';
            case HDMUI_THEMES.DARK_THEME_CLASS:
                return 'LOGO-HEIDELBERG-MULTICOLOR-WHITE';
            default:
                return '';
        }
    }

    get iconLogoName(): string {
        switch (this.theme) {
            case HDMUI_THEMES.LIGHT_THEME_CLASS:
                return 'ICON-HEIDELBERG-MULTICOLOR-NO-GRADIENT';
            case HDMUI_THEMES.DARK_THEME_CLASS:
                return 'ICON-HEIDELBERG-WHITE';
            default:
                return '';
        }
    }

    private buildUrl(fileName: string): string {
        const PATH_PREFIX = 'assets/Logo-Icons/';
        const EXTENSION_SUFFIX = '.svg';

        return `${PATH_PREFIX}${fileName}${EXTENSION_SUFFIX}`;
    }
}
