import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AcceptHeaderInterceptor implements HttpInterceptor {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const headers = request.headers.set('Accept', 'application/json');
        request = request.clone({ headers });
        return next.handle(request);
    }
}
