export enum CCRoleId {
    mdf = 'mdf',
    cfo = 'cfo',
    buc = 'buc',
    sar = 'sar',
    pur = 'pur',
    vpo = 'vpo',
    mam = 'mam',
    mao = 'mao',
    stm = 'stm',
    tra = 'tra',
    adm = 'adm',
    own = 'own',
    mem = 'mem',
    ppl = 'ppl',
    ppm = 'ppm',
    ppo = 'ppo',
    ppa = 'ppa',
    cma = 'cma',
    prm = 'prm',
    pro = 'pro',
    pra = 'pra',
    pom = 'pom',
    poo = 'poo',
    poa = 'poa',
}
