import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, interval } from 'rxjs';
const REFRESH_INTERVAL_1366 = 1366;

export enum LoadingStateAction {
    ADD,
    REMOVE,
}

@Injectable({
    providedIn: 'root',
})
export class LoadingStateService {
    private requestsInProgress = 0;

    private readonly _isLoadingSubj$ = new BehaviorSubject<boolean>(false);
    private readonly _isLoading$ = this._isLoadingSubj$.asObservable();
    private startedRequest = false;
    private intervalIsRunning = false;

    private readonly _isProfileLoadingSubj$ = new BehaviorSubject<boolean>(true);
    private readonly _isProfileLoading$ = this._isProfileLoadingSubj$.asObservable();

    public handleRequest(action: LoadingStateAction): void {
        this.requestsInProgress =
            action === LoadingStateAction.ADD ? this.requestsInProgress + 1 : this.requestsInProgress - 1;
        if (action === LoadingStateAction.ADD) {
            this.startedRequest = true;
            this._isLoadingSubj$.next(this.requestsInProgress > 0);
        }
        if (!this.intervalIsRunning) {
            this.intervalIsRunning = true;
            const loadingSubjIntervall = interval(REFRESH_INTERVAL_1366).subscribe({
                next: () => {
                    if (this.startedRequest === true) {
                        this.startedRequest = false;
                        this._isLoadingSubj$.next(true);
                    } else {
                        if (this.requestsInProgress === 0) {
                            this._isLoadingSubj$.next(false);
                            loadingSubjIntervall.unsubscribe();
                            this.intervalIsRunning = false;
                            this.startedRequest = false;
                        }
                    }
                },
            });
        }
    }

    public get isLoading$(): Observable<boolean> {
        return this._isLoading$;
    }

    public setProfileLoading(state: boolean): void {
        this._isProfileLoadingSubj$.next(state);
    }

    public get isProfileLoading$(): Observable<boolean> {
        return this._isProfileLoading$;
    }
}
