import { inject, Injectable } from '@angular/core';

import { DeviceDetectorService, DeviceType } from 'ngx-device-detector';

@Injectable({ providedIn: 'root' })
export class DeviceTypeService {
    readonly #deviceDetector = inject(DeviceDetectorService);

    public isMobile = false;

    constructor() {
        const isMobileBasedOnUserAgent = this.#deviceDetector.deviceType === DeviceType.Mobile;
        const isMobileBasedOnSize = this.isMobileBasedOnSize();

        this.isMobile = isMobileBasedOnUserAgent && isMobileBasedOnSize;
    }

    private isMobileBasedOnSize(): boolean {
        const PHONE_SIZE_THRESHOLD_PX = 481;
        const screenHeight = screen.availHeight;
        const screenWidth = screen.availWidth;

        const isPortraitMode = screenHeight > screenWidth;
        const isLandscapeMode = screenWidth >= screenHeight;

        return (
            (isPortraitMode && screenWidth < PHONE_SIZE_THRESHOLD_PX) ||
            (isLandscapeMode && screenHeight < PHONE_SIZE_THRESHOLD_PX)
        );
    }
}
