import { HttpClient } from '@angular/common/http';
import { mapToRequestMetadataWithRetry, RequestMetadata } from './map-to-request-metadata';
import { map, Observable, of, switchMap } from 'rxjs';
import { GLOBAL_SPINNER_HTTP_CONTEXT } from '@data-terminal/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserMachines } from '@data-terminal/shared-models';

export interface ApiRequestResponse {
    type: string;
    requestId: string;
    value: object | undefined;
}

export interface ApiResponse<T> {
    type: string;
    requestId: string;
    value: T;
}

export enum ApiRequestType {
    SET_ASSISTANTS = 'SETASSISTANTS',
    SIGN_IN = 'SIGNIN',
    SIGN_OFF = 'SIGNOFF',
    OPERATION_CREATE = 'OPERATIONCREATE',
    ORDERBAG = 'ORDERBAG',
    PRESETTING = 'PRESETTING',
    GETCOMMENTS = 'GETCOMMENTS',
    STARTSAMEOPERATION = 'STARTSAMEOPERATION',
}

export interface PreSettingRequest {
    primaryKey: string;
    machineId: string;
}

export interface PreSettingRequestResponse {
    type: ApiRequestType;
    requestId: string;
    value: object | undefined;
}

export interface PreSettingResponse {
    back: string;
    front: string;
    machineClass: string;
    customerName: string;
    foldDirection: FoldDirection[];
}

export interface FoldDirection {
    foldLength: string;
    direction: string;
}

export interface GetCommentsResponse {
    date: number;
    text: string;
    type: string;
    author: string;
}

export interface GetCommentsBody {
    machineId: string;
    jobId: string;
}

export function sendApiRequest<T, V>( //NOSONAR
    snackBar: MatSnackBar,
    type: ApiRequestType,
    body: T,
    userId: string,
    httpClient: HttpClient,
    orgRequestPrefix: string,
    backendUrl: string,
    machineId?: string[]
): Observable<RequestMetadata<V>> {
    const prefix = backendUrl + orgRequestPrefix;

    return httpClient
        .post<ApiRequestResponse>(`${prefix}apiRequest`, {
            type,
            value: body,
        })
        .pipe(
            switchMap((response) =>
                response.requestId === 'MOCK'
                    ? of(response.value as V).pipe(
                          mapToRequestMetadataWithRetry({
                              delayFactorFn: () => 1,
                              baseDelayMs: 1000,
                              maxRetries: 15,
                          })
                      )
                    : httpClient
                          .get<ApiResponse<V>>(`${prefix}apiResponse/${response.requestId}`, {
                              context: GLOBAL_SPINNER_HTTP_CONTEXT,
                          })
                          .pipe(
                              map((httpResponse) => httpResponse.value),
                              mapToRequestMetadataWithRetry({
                                  delayFactorFn: () => 1,
                                  baseDelayMs: 1000,
                                  maxRetries: 15,
                              })
                          )
            ),
            map((response) => {
                if (ApiRequestType.SIGN_IN === type && response.data !== undefined) {
                    const responseData = response.data as UserMachines;
                    const matchedMachine = responseData.assignedMachines.find(
                        (machine) => machineId && machineId.includes(machine.machineId)
                    );

                    if (matchedMachine) {
                        const signedOnUsers = matchedMachine.signedOnUsers;
                        const loggedInUser = userId;
                        const userMatch = signedOnUsers.find(
                            (user) =>
                                user.userId === loggedInUser &&
                                user.role === 'Assistant' &&
                                user.captain &&
                                machineId &&
                                machineId.includes(matchedMachine.machineId)
                        );
                        if (userMatch) {
                            snackBar.open(
                                `No operator is signed in at machine ${matchedMachine.machineId}, you have temp. access to all actions.` +
                                    ` Note, if an operator signs in your permissions will be restricted again`,
                                'Close',
                                {
                                    duration: 8000,
                                }
                            );
                        }
                    }
                }
                return response;
            })
        );
}
