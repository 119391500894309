import { MachineClass } from '@data-terminal/shared-models';
import { Entity } from './common';

export type OperationSettingsEntry = OperationSettingsEntryBody & Entity;

export interface OperationSettingsEntryBody {
    deviceClass: MachineClass;
    automaticallyLoadAllByDeviceClass: boolean;
    automaticallyCreateUnplanned: boolean;
    materialConsumption: boolean;
    batchReport: boolean;
}

export enum GeneralOperationSettings {
    automaticallyLoadAllByDeviceClass = 'automaticallyLoadAllByDeviceClass',
    automaticallyCreateUnplanned = 'automaticallyCreateUnplanned',
    materialConsumption = 'materialConsumption',
    batchReport = 'batchReport',
}

export interface GeneralOperationSetting {
    settingName: string;
    settingValue: boolean;
    disabled: boolean;
    translationKey: string;
    isSubmenuOption: boolean;
    helpTextTranslationKey?: string;
}
