/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { RequestMetadata } from '@data-terminal/utils';

@Directive({
    selector: '[isError]', // eslint-disable-line
})
export class IsErrorDirective {
    constructor(
        private readonly templateRef: TemplateRef<any>,
        private readonly viewContainer: ViewContainerRef
    ) {}

    @Input() set isError(metadata: RequestMetadata<any>) {
        if (!metadata.isLoading && metadata.error) {
            if (this.viewContainer.length === 0) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
        } else {
            this.viewContainer.clear();
        }
    }
}
