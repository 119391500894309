import { DataTerminalError, OperationActionSignOff } from '@data-terminal/shared-models';
import { Entity } from './common';

export interface Operation {
    plannedMakeReady: number;
    plannedGoodProduction: number;
    plannedTotal: number;
    actualMakeReady: number;
    actualGoodProduction: number;
    actualTotal: number;
    primaryKey: string;
    opId: string;
    opName: string;
    opPlannedGoodCtr: number;
    opPlannedWasteCtr: number;
    opCtrGood: number;
    opCtrWaste: number;
    lastUpdate: number;
    opState: ActivityState;
    opNeedsAvailable: OperationNeedsAvailable;
    opPlannedStart: number;
    dueDate: number;
    jobNumber: string;
    jobName: string;
    plannedMachine: string;
    goodAmount: number;
    wasteAmount: number;
    runningActivities: string[];
    running: boolean;
    customerName: string | null;
    machines: string[];
    versions: string;
    article: string;
    serviceError: DataTerminalError;
    sheetId: string;
    setup?: string;
    operationId?: string;
    parts?: OperationPart[];
    plannedEnd?: number;
    specialOperationInfo: string;
    setupStart: number;
    productionStart: number;
    stoppedStart: number;
    cleanupStart: number;
    actualStopped: number;
    actualCleanup: number;
    pred_good: string;
    runningOnMachine?: string; // will be a required field; for now only new or updated operations got this field
    updatedAt: string;
}

export interface OperationDetails {
    status: string;
    jobNumber: string;
    jobName: string;
    machine: string;
    dueDate: number;
    deliveryQuantity: number;
    plannedStart: number;
    plannedEnd: number;
    plannedMakeReady: number;
    plannedGoodProduction: number;
    plannedTotal: number;
    actualMakeReady: number;
    actualGoodProduction: number;
    actualTotal: number;
    plannedAmount: number;
    plannedGood: number;
    plannedWaste: number;
    producedAmount: number;
    producedGood: number;
    producedWaste: number;
    brand: string;
    grammage: string;
    paperGrade: number;
    thickness: number;
    grainDirection: string;
    dimension: string;
    setupInformation: string;
    productFormat: string;
    versions: string;
    article: string;
    groupName: string;
    sheetId: string;
    foldingType: string;
    operationId: string;
    primaryKey: string;
    opId: string;
    opName: string;
    frontPreView: string;
    backPreView: string;
    customerName?: string;
    paperId: string;
    operationStatus: Operation;
}

export interface SetCounterBody {
    machineId: string;
    primaryKey: string;
    goodCount?: number;
    wasteCount?: number;
    comment?: string;
}

export interface OperationPart {
    partMap: {
        SignatureName: string;
        SheetName: string;
        PartVersion?: string;
    };
}

export enum OperationNeedsAvailable {
    RED = 'RED',
    YELLOW = 'YELLOW',
    GREEN = 'GREEN',
}

export enum ActivityState {
    WAITING = 'Waiting',
    SETUP = 'Setup',
    IN_PROGRESS = 'InProgress',
    SUSPENDED = 'Suspended',
    PAUSED = 'Paused',
    COMPLETED = 'Completed',
    STOPPED = 'Stopped',
    CLEANUP = 'Cleanup',
}

export interface Activity {
    actId: string;
    actName: string;
    groupId: string;
    groupName: string;
    serviceError: DataTerminalError;
    required: ActivityRequired[];
    defaultActivity: DefaultActivity;
    runList: RunList[];
    machineId: string;
    running: boolean;
    jobStatus: string;
    updatedAt?: string;
}

export interface RunList {
    email: string;
    startDate: number;
    duration: number;
}

export enum ActivityRequired {
    OTHER_ACTIVITY = 'OTHER_ACTIVITY',
    OPERATION = 'OPERATION',
}

export enum DefaultActivity {
    MAKE_READY = 'MAKE_READY',
    PRODUCTION = 'PRODUCTION',
    NONE = 'NONE',
}

export type GroupedActivities = {
    activities: Activity[];
    showExpandedPanel: boolean;
};

export interface OperationAction {
    goodAmount?: number;
    wasteAmount?: number;
    comment?: string;
    actionType: OperationActionType;
}

export interface StartOperationActivityBody {
    primaryKey: string;
    machineId: string;
    activityId: string;
    wasteCount?: number;
    goodCount?: number;
    operationAction?: OperationActionSignOff;
    comment?: string;
}

export interface StartOperationActivity {
    activity: Activity;
    operation: Operation;
    machineId: string;
    currentRunningOperation?: OperationAction;
}

export interface OperationActionRequest {
    operationAction: OperationAction;
    machineId: string;
    primaryKey: string;
}

export enum OperationActionType {
    PAUSE = 'PAUSE',
    STOP = 'STOP',
    ADD_REPORT = 'ADD_REPORT',
    MAKE_READY = 'MAKE_READY',
}

export interface NewOperation {
    goodAmount?: number;
    wasteAmount?: number;
    opName?: string;
    jobId?: string;
    comment?: string;
    makeReadyTimeHour?: number;
    makeReadyTimeMinutes?: number;
    productionRunTimeHours?: number;
    productionRunTimeMinutes?: number;
    noAmountsRequired?: boolean;
}

export interface CreateOperation {
    newOperation: NewOperation;
    machineId?: string;
    userId?: string;
}

export enum OperationCardAttribute {
    jobName = 'jobName',
    jobNumber = 'jobNumber',
    opName = 'opName',
    opState = 'opState',
    opPlannedStart = 'opPlannedStart',
    opPlannedGoodCtr = 'opPlannedGoodCtr',
    versions = 'versions',
    plannedMachine = 'plannedMachine',
    setup = 'setup',
    dueDate = 'dueDate',
    article = 'article',
    sheet = 'sheet',
    specialOperationInfo = 'specialOperationInfo',
}

export interface OperationCardEntity extends Entity {
    machineClass: string;
    cardAttributes: { visible: boolean; id: string }[];
}

export interface SetCommentParam {
    primaryKey: string;
    comment: string;
}
