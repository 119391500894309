import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { SnackBarModule } from './snack-bar.module';
import { AriaLivePoliteness } from '@angular/cdk/a11y';

@Injectable({
    providedIn: SnackBarModule,
})
export class SnackBarService {
    private readonly duration = 5000;

    constructor(private readonly _snackBar: MatSnackBar) {}

    public openSnackBar(
        message: string,
        className: string,
        verticalPosition: MatSnackBarVerticalPosition = 'bottom',
        horizontalPosition: MatSnackBarHorizontalPosition = 'center',
        politeness: AriaLivePoliteness = 'polite'
    ): void {
        this._snackBar.open(message, '', {
            panelClass: className,
            duration: this.duration,
            verticalPosition: verticalPosition,
            horizontalPosition: horizontalPosition,
            politeness: politeness,
        });
    }
}
