export type RequiredDecorator = (target: object, propertyKey: string) => void;

export function Required(): RequiredDecorator {
    return function (target: object, propertyKey: string) {
        Object.defineProperty(target, propertyKey, {
            get() {
                console.error(`[${target.constructor.name}] Attribute '${propertyKey}' is required`);
            },
            set(value) {
                Object.defineProperty(target, propertyKey, {
                    value,
                    writable: true,
                    configurable: true,
                });
            },
            configurable: true,
        });
    };
}
