<hdmui-base-dialog
    [dialogOptions]="cancleOnly ? DIALOG_OPTION_CANCLE : DIALOG_OPTION_OK_CANCLE"
    [okText]="'Retry'"
    (cancelClicked)="cancleOnly ? closeDialog() : cancleClick()"
    (okClicked)="localRetry ? localRetryClick() : retryClick()"
>
    <div class="error-dialog__container">
        <h2 class="hdmui-m-0">{{ 'DC.ERROR_NOTIFICATION.' + data.errorCode + '.ERROR_TITLE' | translate }}</h2>
        <p class="error-dialog__message">
            {{ 'DC.ERROR_NOTIFICATION.' + data.errorCode + '.ERROR_TEXT' | translate }}
        </p>
        @if ((data.errorCode === 'ERR_SIGNIN' || data.errorCode === 'ERR_SIGNOFF') && errorList.length > 0) {
            <div>
                <p>{{ 'DC.ERROR_NOTIFICATION.UNSUCCESSFUL_MACHINES' | translate }}</p>
                @for (item of errorList; track item) {
                    <p>- {{ item.item.machineId }}</p>
                }
            </div>
        }
    </div>
</hdmui-base-dialog>
