import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgModule,
    OnInit,
    Output,
} from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { HdmuiAvatarComponent, HdmuiComponentsModule } from '@heidelberg/hdmui-angular';

import {
    Activity,
    ActivityState,
    CaptureMode,
    Machine,
    MachineListAction,
    MachineListActionType,
    Role,
    ROUTE_PATH,
    SignedOnUser,
} from '@data-terminal/shared-models';

import { MachineIconPipe } from '../../pipes/machine-icon.pipe';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { interval, take } from 'rxjs';

const REFRESH_INTERVAL_5000 = 5000;
const TAKE_5 = 5;

export interface MachineCardItem {
    operation: string | undefined;
    activity: string | undefined;
}

@Component({
    selector: 'data-terminal-machine-card',
    templateUrl: './machine-card.component.html',
    styleUrls: ['./machine-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class MachineCardComponent implements OnInit {
    @Input() public machine!: Machine;
    @Input() public otherActivities!: Activity[];
    @Input() public isSelected?: boolean = false;
    @Input() public isSelectableMode?: boolean = false;
    @Input() public showOperationInfo?: boolean = false;
    @Input() public captureMode!: CaptureMode;
    @Output() public selectCard: EventEmitter<MachineListAction> = new EventEmitter<MachineListAction>();

    public operatorEmail: string | undefined;
    public assistants: SignedOnUser[] = [];

    public RoutePath = ROUTE_PATH;

    public readonly machineCardItem: MachineCardItem = {
        operation: undefined,
        activity: undefined,
    };

    private readonly oldMachineCardItem: MachineCardItem = {
        operation: undefined,
        activity: undefined,
    };

    private updateOnChange(newObject: MachineCardItem, oldObject: MachineCardItem): void {
        if (newObject !== oldObject) {
            this.cdRef.detectChanges();
        }
    }

    private updateMachineCard(): void {
        if (this.machine.runningOperation?.opName !== undefined) {
            this.machineCardItem.activity = this.machine.runningOperation.activityName;
            this.machineCardItem.operation = this.machine.runningOperation.opName;
            this.updateOnChange(this.machineCardItem, this.oldMachineCardItem);
            return;
        } else {
            if (this.otherActivities === undefined) {
                this.updateOnChange(this.machineCardItem, this.oldMachineCardItem);
                return;
            }
            const activity = this.otherActivities
                ?.filter((act) => act.machineId === this.machine.machineId)
                .find((act) => act.running);
            if (activity) {
                this.machineCardItem.activity = activity.actName;
                this.machineCardItem.operation = '-';
                this.updateOnChange(this.machineCardItem, this.oldMachineCardItem);
                return;
            }
        }
        this.machineCardItem.activity = undefined;
        this.machineCardItem.operation = undefined;
    }

    constructor(private readonly cdRef: ChangeDetectorRef) {
        interval(100)
            .pipe(take(TAKE_5))
            .subscribe(() => {
                this.updateMachineCard();
            });
        interval(1000)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.updateMachineCard();
            });

        // this.otherActivities$.pipe(untilDestroyed(this)).subscribe((otherActivities) => {
        //     console.log('........................')

        //     if (this.machine !== undefined) {
        //         console.log('machine not undefined')
        //         console.log('runningOperation: ', this.machine.runningOperation)
        //         const data = otherActivities.data
        //         if (this.machine.runningOperation !== null && this.machine.runningOperation !== undefined && this.machine.runningOperation.opName !== undefined) {
        //             console.log('machine-path')
        //             this.machineCardItem.operation = this.machine.runningOperation.opName
        //             this.machineCardItem.activity = this.machine.runningOperation.activityName
        //             cdRef.detectChanges()
        //             console.log('MachineCardItem: ', this.machineCardItem)
        //             return
        //         }
        //         if (data !== null && data !== undefined) {
        //             console.log('activity-path')
        //             const machineActivities = data.filter((d) => d.machineId === this.machine.machineId)
        //             const machineRunningActivity = machineActivities.find((d => d.running))
        //             console.log(data)
        //             if (machineRunningActivity) {
        //                 this.machineCardItem.operation = '-'
        //                 this.machineCardItem.activity = machineRunningActivity.actName
        //                 cdRef.detectChanges()
        //                 console.log('MachineCardItem: ', this.machineCardItem)
        //                 return
        //             }
        //             if (machineActivities.length > 0 && !machineRunningActivity) {
        //                 this.machineCardItem.operation = undefined
        //                 this.machineCardItem.activity = undefined
        //             }
        //         }
        //     }
        // })
    }

    public ngOnInit(): void {
        this.assistants = this.getAssistants(this.machine);
        let operator = this.getActiveOperator(this.machine);
        if (operator) {
            this.operatorEmail = operator.userId;
        }
        interval(REFRESH_INTERVAL_5000).subscribe(() => {
            this.assistants = this.getAssistants(this.machine);
            operator = this.getActiveOperator(this.machine);
            if (operator) {
                this.operatorEmail = operator.userId;
            }
        });
    }

    public onSelectCard(): void {
        this.isSelected = !this.isSelected;
        this.selectCard.emit({
            payload: this.machine,
            action: this.isSelected ? MachineListActionType.ADD : MachineListActionType.REMOVE,
        });
    }

    public isCompleted(): boolean {
        return this.machine?.runningOperation?.activityName === ActivityState.COMPLETED;
    }

    private getActiveOperator(machine: Machine): SignedOnUser | undefined {
        return machine.signedOnUsers.find((user) => user.role === Role.OPERATOR || user.captain);
    }

    private getAssistants(machine: Machine): SignedOnUser[] {
        return machine.signedOnUsers.filter((user) => user.role === Role.ASSISTANT && !user.captain);
    }

    protected readonly CaptureMode = CaptureMode;
}

@NgModule({
    declarations: [MachineCardComponent],
    exports: [MachineCardComponent],
    imports: [
        MatCardModule,
        CommonModule,
        MatButtonModule,
        MatIconModule,
        HdmuiComponentsModule,
        MatTooltipModule,
        MatProgressBarModule,
        RouterModule,
        TranslateModule,
        HdmuiAvatarComponent,
        MachineIconPipe,
    ],
})
export class MachineCardModule {}
