import { Pipe, PipeTransform } from '@angular/core';

import { MachineClass } from '@data-terminal/shared-models';

@Pipe({
    standalone: true,
    name: 'machineIcon',
})
export class MachineIconPipe implements PipeTransform {
    private readonly machineIconsMap = new Map<MachineClass, string>([
        [MachineClass.ID_ADHESIVEBINDER, 'hdmui:bookGlue'],
        [MachineClass.ID_ADHESIVEBINDERLINE, 'hdmui:bookProductionGlue'],
        [MachineClass.ID_BOOKPRODUCTIONLINE, 'hdmui:bookProduction'],
        [MachineClass.ID_BOXMAKER, 'hdmui:boxGlue'],
        [MachineClass.ID_CASEMAKER, 'hdmui:bookFinishing'],
        [MachineClass.ID_CUTTER, 'hdmui:rteCut'],
        [MachineClass.ID_DIGITALPRINTER, 'hdmui:print'],
        [MachineClass.ID_GATHERER, 'hdmui:gatherer'],
        [MachineClass.ID_MANUALPOSTPRESS, 'hdmui:manualPostPress'],
        [MachineClass.ID_MANUALPREPRESS, 'hdmui:manualPrePress'],
        [MachineClass.ID_MANUALPRESS, 'hdmui:manualPress'],
        [MachineClass.ID_SHAPECUTTER, 'hdmui:puzzleCutter'],
        [MachineClass.ID_SHEETFEDPRESS, 'hdmui:offset'],
        [MachineClass.ID_SHEETFOLDER, 'hdmui:sheetFolder'],
        [MachineClass.ID_STITCHER, 'hdmui:saddleStitcher'],
        [MachineClass.ID_THREADSEWER, 'hdmui:threadStitcher'],
        [MachineClass.ID_TRIMMER, 'hdmui:trimmer'],
    ]);

    transform(machineClass: MachineClass): string {
        return this.machineIconsMap.get(machineClass) || 'hdmui:imageUnavailable';
    }
}
