import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BACKEND_URL, ORGANIZATION_REQUEST_PREFIX } from '@data-terminal/data-access';
import { DCNotificationRequest, DCNotificationResponse } from 'projects/shared-models/src/lib/notification.';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NotificationSendingApiService {
    private readonly NOTIFICATION_EVENT_URL = `${this.backendUrl}${this.orgRequestPrefix}notifications`;

    constructor(
        private readonly http: HttpClient,
        @Inject(BACKEND_URL) private readonly backendUrl: string,
        @Inject(ORGANIZATION_REQUEST_PREFIX) private readonly orgRequestPrefix: string
    ) {}

    public sendNotificationEvent(body: DCNotificationRequest): Observable<DCNotificationResponse> {
        return this.http.post<DCNotificationResponse>(this.NOTIFICATION_EVENT_URL, body);
    }
}
