import { inject, InjectionToken } from '@angular/core';
import { WINDOW } from './window.injection-token';

export const LOCAL_STORAGE = new InjectionToken<Storage>('Wrapper around localStorage', {
    providedIn: 'root',
    factory: () => {
        const window = inject(WINDOW);
        return window.localStorage;
    },
});
