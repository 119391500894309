import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    standalone: true,
    selector: 'data-terminal-help-button',
    templateUrl: 'help-button.component.html',
    styleUrls: ['help-button.component.scss'],
    imports: [CdkConnectedOverlay, CdkOverlayOrigin, MatButtonModule, MatCardModule, MatIconModule, TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpButtonComponent {
    public isOpen = false;
}
