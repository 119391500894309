import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';

import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

import { HdmuiEmptyStatesModule, HdmuiIconsModule } from '@heidelberg/hdmui-angular';

import { Activity, Machine, MachineListAction, MachineListActionType } from '@data-terminal/shared-models';
import { isCurrentUserAnOperator } from '@data-terminal/utils';

import { MachineCardModule } from '../machine-card/machine-card.component';
import { CustomDividerModule } from '../custom-divider/custom-divider.component';

@Component({
    standalone: true,
    imports: [
        MachineCardModule,
        MatDividerModule,
        CustomDividerModule,
        TranslateModule,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        MachineCardModule,
        MachineCardModule,
        CustomDividerModule,
        HdmuiEmptyStatesModule,
        HdmuiIconsModule,
    ],
    selector: ' data-terminal-machine-list',
    templateUrl: './machine-list.component.html',
    styleUrls: ['./machine-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MachineListComponent implements OnInit, OnChanges {
    @Input() public machines: Machine[] = [];
    @Input() public previousMachines: Machine[] = [];
    @Input() public otherActivities: Activity[] = [];
    @Input() public isSelectableMode?: boolean = false;
    @Input() public showOperationInfo?: boolean = false;
    @Input() public inDialog?: boolean = false;
    @Input() public userId?: string;
    @Input() public emptyStateTitle?: string;
    @Input() public emptyStateDescription?: string;
    @Input() public emptyStateButtonText = '';
    @Output() public emptyStateButtonClick = new EventEmitter<void>();
    @Output() public selectedMachines: EventEmitter<Machine[]> = new EventEmitter<Machine[]>();

    private _selected: Machine[] = [];
    private _selectionStates: { machineId: string; state: boolean }[] = [];

    public ngOnInit(): void {
        this._selected = [];
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.machines) {
            this._selectionStates = this.machines.map((m) => {
                return { machineId: m.machineId, state: this._selected.map((s) => s.machineId).includes(m.machineId) };
            });
        }
        if (changes.previousMachines) {
            this.machines = this.machines.filter(
                (toDisplay) => !this.previousMachines.find((toSelect) => toDisplay.machineId === toSelect.machineId)
            );
        }
    }

    public onSelectCard({ payload, action }: MachineListAction): void {
        if (action === MachineListActionType.ADD) {
            this._selected.push(payload);
            const selectedStateReference = this._selectionStates.filter((m) => m.machineId === payload.machineId);
            if (selectedStateReference.length > 0) {
                selectedStateReference[0].state = true;
            }
        } else {
            this._selected = this._selected.filter((e) => e.machineId !== payload.machineId);
            const selectedStateReference = this._selectionStates.filter((m) => m.machineId === payload.machineId);
            if (selectedStateReference.length > 0) {
                selectedStateReference[0].state = false;
            }
        }
        this.selectedMachines.emit(this._selected);
    }

    public shouldDisplayCard(machine: Machine): boolean {
        return isCurrentUserAnOperator(machine, this.userId || '');
    }

    public identify(index: number, item: Machine): string {
        return item.machineId;
    }

    public onEmptyStateButtonClick(): void {
        return this.emptyStateButtonClick.emit();
    }

    public selectionStateObject(machineId: string): { machineId: string; state: boolean } {
        const result = this._selectionStates.find((m) => m.machineId === machineId);
        return result ?? { machineId: machineId, state: false };
    }
}
