import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { CCAuthService } from '@heidelberg/control-center-frontend-integration/auth';
import { CCPerson } from '@heidelberg/control-center-frontend-integration/cc';

import { DataTerminalSessionService } from '@data-terminal/data-access';
import { ROUTE_PARAMS, ROUTE_PATH, User } from '@data-terminal/shared-models';
import { LoadingStateService } from '@data-terminal/utils';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class AccessControlService {
    private _isProfileLoading = false;

    constructor(
        private readonly sessionService: DataTerminalSessionService,
        private readonly ccAuthService: CCAuthService,
        private readonly router: Router,
        private readonly loadingStateService: LoadingStateService
    ) {
        this.loadingStateService.isProfileLoading$.pipe(untilDestroyed(this)).subscribe((isProfileLoading) => {
            this._isProfileLoading = isProfileLoading;
        });
    }

    public canActivate(route: ActivatedRouteSnapshot): boolean {
        console.log('Snapshot: ', route);
        if (!this.hasRouteOrgParam(route)) {
            return true;
        }

        if (this._isProfileLoading) {
            return false;
        }

        const { user, dataTerminalUser } = this.getUsers();

        if (this.isDataTerminalUserMissing(user, dataTerminalUser)) {
            this.router.navigateByUrl(ROUTE_PATH.userNotFound);
            return false;
        }

        if (this.isUserValid(user, dataTerminalUser, route)) {
            return true;
        }

        this.router.navigateByUrl(ROUTE_PATH.error);
        return false;
    }

    private hasRouteOrgParam(route: ActivatedRouteSnapshot): boolean {
        return route.params && route.params[ROUTE_PARAMS.orgId];
    }

    private getUsers(): { user: CCPerson | undefined; dataTerminalUser: User } {
        return {
            user: this.ccAuthService.getCurrentUser(),
            dataTerminalUser: this.sessionService.getCurrentDataTerminalUser(),
        };
    }

    private isUserValid(user: CCPerson | undefined, dataTerminalUser: User, route: ActivatedRouteSnapshot): boolean {
        return (
            dataTerminalUser !== undefined &&
            user !== undefined &&
            user !== null &&
            user.memberships?.length > 0 &&
            user.memberships.filter((membership) => membership.organization.id === route.params[ROUTE_PARAMS.orgId])
                .length > 0
        );
    }

    private isDataTerminalUserMissing(user: CCPerson | undefined, dataTerminalUser: User): boolean {
        return user !== undefined && user !== null && dataTerminalUser === undefined;
    }
}
