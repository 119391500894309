import { Machine, Role } from '@data-terminal/shared-models';

export function isCurrentUserAnOperator(machine: Machine, userId: string): boolean {
    return !!machine.signedOnUsers.find(
        (members) => members.userId === userId && (members.role === Role.OPERATOR || members.captain)
    );
}

export function isCurrentUserAnAssistance(machine: Machine, userId: string): boolean {
    return !!machine.signedOnUsers.find((members) => members.userId === userId && members.role === Role.ASSISTANT);
}
