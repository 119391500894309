import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BACKEND_URL, ORGANIZATION_REQUEST_PREFIX, OrganizationInfo } from '@data-terminal/data-access';

@Injectable({
    providedIn: 'root',
})
export class OrganizationApiService {
    readonly #httpClient = inject(HttpClient);
    readonly #backendUrl = inject(BACKEND_URL);
    readonly #orgRequestPrefix = inject(ORGANIZATION_REQUEST_PREFIX);

    readonly #organizationInfoUrl = `${this.#backendUrl}${this.#orgRequestPrefix}getOrganizationInfo`;

    public getOrganizationInfo(): Observable<OrganizationInfo | undefined> {
        return this.#httpClient.get<OrganizationInfo | undefined>(this.#organizationInfoUrl);
    }
}
