<button mat-icon-button (click)="isOpen = true" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
    <mat-icon svgIcon="hdmui:help"></mat-icon>
</button>
<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen"
    (overlayOutsideClick)="isOpen = false"
    (overlayKeydown)="isOpen = false"
>
    <mat-card class="help__card">
        <div class="hdmui-content">
            <ng-content></ng-content>
        </div>
    </mat-card>
</ng-template>
