import { Component, Input, NgModule, ViewEncapsulation } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { HdmuiComponentsModule } from '@heidelberg/hdmui-angular';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'data-terminal-error-message',
    templateUrl: './error-message.component.html',
    styleUrls: ['./error-message.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ErrorMessageComponent {
    @Input() title?: string;
    @Input() description?: string;
}

@NgModule({
    imports: [MatIconModule, HdmuiComponentsModule, TranslateModule.forChild(), CommonModule],
    declarations: [ErrorMessageComponent],
    exports: [ErrorMessageComponent],
})
export class ErrorMessageComponentModule {}
