import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Optional, Inject, NgModule, Component, Input } from '@angular/core';
import { CCRoleId } from '@heidelberg/control-center-frontend-integration/cc';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { first, Subject, BehaviorSubject, timer, switchMap, of, retryWhen, concat, map, shareReplay, takeUntil, mergeMap, throwError, finalize } from 'rxjs';
import * as i1$1 from '@angular/common/http';
import { HttpStatusCode } from '@angular/common/http';
const _c0 = ["membership", ""];
function DataProcessingAgreementLinkComponent_a_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 1);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("href", ctx_r0.dataProcessingLink, i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.dataProcessingLabelText);
  }
}
const STAGE_LOCAL = 'local';
const STAGE_DEV = 'dev';
const STAGE_STAGE = 'stage';
const STAGE_PROD = 'prod';
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add 'isEdge' to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const isIE = msie > 0 || msie11 > 0;
const LOCATION_TOKEN = new InjectionToken('Window location object');
const CONTROL_CENTER_SVC_URL_TOKEN = new InjectionToken('CC service url', {
  providedIn: 'root',
  factory: () => stage => {
    switch (stage) {
      case STAGE_LOCAL:
      case STAGE_DEV:
      case STAGE_STAGE:
        return 'https://cc.stage.heidelberg.cloud/api/public/v1';
      default:
        return 'https://home.plus.heidelberg.com/api/public/v1';
    }
  }
});
const CONTROL_CENTER_UI_URL_TOKEN = new InjectionToken('CC UI url', {
  providedIn: 'root',
  factory: () => stage => {
    switch (stage) {
      case STAGE_LOCAL:
      case STAGE_DEV:
      case STAGE_STAGE:
        return 'https://cc.stage.heidelberg.cloud';
      default:
        return 'https://home.plus.heidelberg.com';
    }
  }
});
const NOTIFICATION_SVC_URL_TOKEN = new InjectionToken('notification svc url', {
  providedIn: 'root',
  factory: () => stage => {
    switch (stage) {
      case STAGE_LOCAL:
      case STAGE_DEV:
      case STAGE_STAGE:
        return 'https://notification.cc.stage.heidelberg.cloud/api/public/v1';
      default:
        return 'https://notification.home.plus.heidelberg.com/api/public/v1';
    }
  }
});
/**
 * Provides URLs for various services.
 */
class ConfigService {
  constructor(location, controlCenterSvcUrlMapper, controlCenterUIUrlMapper, notificationSvcUrlMapper) {
    this.controlCenterSvcUrlMapper = controlCenterSvcUrlMapper;
    this.controlCenterUIUrlMapper = controlCenterUIUrlMapper;
    this.notificationSvcUrlMapper = notificationSvcUrlMapper;
    this.location = location ?? window.location;
    this.stage = this.determineStage();
  }
  isIEBrowser() {
    return isIE;
  }
  determineStage() {
    const origin = this.location.origin.toLowerCase();
    if (origin.includes('.dev.')) {
      return STAGE_DEV;
    } else if (origin.includes('.stage.')) {
      return STAGE_STAGE;
    } else if (origin.includes('localhost')) {
      return STAGE_LOCAL;
    }
    return STAGE_PROD;
  }
  getControlCenterSvcUrl() {
    return this.controlCenterSvcUrlMapper(this.stage);
  }
  getControlCenterUIUrl() {
    return this.controlCenterUIUrlMapper(this.stage);
  }
  getNotificationSvcUrl() {
    return this.notificationSvcUrlMapper(this.stage);
  }
  isProductionEnvironment() {
    return this.stage === STAGE_PROD;
  }
  getPortalHomeLink(activeOrganizationId, currentTheme) {
    return `${this.getControlCenterUIUrl()}?organization_id=${activeOrganizationId}&theme=${currentTheme}`;
  }
  getGlobalNotificationSettingsLink(activeOrganizationId, appId, currentTheme) {
    return `${this.getControlCenterUIUrl()}?flow=notificationsettings&organization_id=${activeOrganizationId}&appId=${appId}&returnUrl=${encodeURIComponent(this.location.href)}&theme=${currentTheme}`;
  }
  static {
    this.ɵfac = function ConfigService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ConfigService)(i0.ɵɵinject(LOCATION_TOKEN, 8), i0.ɵɵinject(CONTROL_CENTER_SVC_URL_TOKEN), i0.ɵɵinject(CONTROL_CENTER_UI_URL_TOKEN), i0.ɵɵinject(NOTIFICATION_SVC_URL_TOKEN));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ConfigService,
      factory: ConfigService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ConfigService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: Location,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [LOCATION_TOKEN]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [CONTROL_CENTER_SVC_URL_TOKEN]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [CONTROL_CENTER_UI_URL_TOKEN]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NOTIFICATION_SVC_URL_TOKEN]
    }]
  }], null);
})();
class ControlCenterNotificationModule {
  static {
    this.ɵfac = function ControlCenterNotificationModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ControlCenterNotificationModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ControlCenterNotificationModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [{
        provide: LOCATION_TOKEN,
        useValue: window.location
      }, ConfigService]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ControlCenterNotificationModule, [{
    type: NgModule,
    args: [{
      providers: [{
        provide: LOCATION_TOKEN,
        useValue: window.location
      }, ConfigService],
      declarations: [],
      imports: [],
      exports: []
    }]
  }], null, null);
})();
const CC_TEXTKEY_DATAPROCESSING_AGREEMENT_LINK = '*.privacy.processingagreement.link';
const CC_TEXTKEY_DATAPROCESSING_AGREEMENT_LABEL = '*.privacy.processingagreement.label';
/**
 * A link to dataprocessing-agreement that is only visible if it is required for users current organization.
 *
 * Usage:
 *
 * Add the following import to your module and include it.
 * ```ts
 *  import { LegalUtilsModule } from '@heidelberg/control-center-frontend-integration';
 * ```
 *
 * HTML:
 * ```html
 *  <hdmui-legal-line ...>
 *    <ng-container customLinkContainer>
 *      <cc-utils-data-processing-agreement-link
 *        [membership]="membership"
 *        [dataProcessingLabelText]="'*.privacy.processingagreement.label' | translate"
 *        [dataProcessingLink]="'*.privacy.processingagreement.link' | translate">
 *      </cc-utils-data-processing-agreement-link>
 *    </ng-container>
 * </hdmui-legal-line>
 * ```
 */
class DataProcessingAgreementLinkComponent {
  constructor() {
    this.isVisible = false;
    this.dataProcessingLabelText = '';
    this.dataProcessingLink = '';
  }
  ngOnChanges(_changes) {
    this.updateDisplay();
  }
  ngOnInit() {
    if (!this.dataProcessingLabelText) {
      throw new Error('Missing text for dataProcessingLabelText');
    }
    if (!this.dataProcessingLink) {
      throw new Error('Missing text for dataProcessingLink');
    }
    this.updateDisplay();
  }
  updateDisplay() {
    if (!this.membership) {
      this.isVisible = false;
      return;
    }
    if (this.membership) {
      if (this.membership.organization.requiresShowingDataProtectionAgreement && this.membership.ccRoles.includes(CCRoleId.own)) {
        this.isVisible = true;
        return;
      }
    }
    this.isVisible = false;
  }
  static {
    this.ɵfac = function DataProcessingAgreementLinkComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DataProcessingAgreementLinkComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DataProcessingAgreementLinkComponent,
      selectors: [["cc-utils-data-processing-agreement-link", "membership", ""]],
      inputs: {
        membership: "membership",
        dataProcessingLabelText: "dataProcessingLabelText",
        dataProcessingLink: "dataProcessingLink"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      attrs: _c0,
      decls: 1,
      vars: 1,
      consts: [["target", "_blank", 3, "href", 4, "ngIf"], ["target", "_blank", 3, "href"]],
      template: function DataProcessingAgreementLinkComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, DataProcessingAgreementLinkComponent_a_0_Template, 2, 2, "a", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", ctx.isVisible);
        }
      },
      dependencies: [i1.NgIf],
      styles: ["[_nghost-%COMP%]{display:flex}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DataProcessingAgreementLinkComponent, [{
    type: Component,
    args: [{
      selector: 'cc-utils-data-processing-agreement-link[membership]',
      template: "<a [href]=\"dataProcessingLink\" *ngIf=\"isVisible\" target=\"_blank\">{{\n  dataProcessingLabelText\n}}</a>\n",
      styles: [":host{display:flex}\n"]
    }]
  }], null, {
    membership: [{
      type: Input
    }],
    dataProcessingLabelText: [{
      type: Input
    }],
    dataProcessingLink: [{
      type: Input
    }]
  });
})();
class LegalUtilsModule {
  static {
    this.ɵfac = function LegalUtilsModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LegalUtilsModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: LegalUtilsModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LegalUtilsModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [DataProcessingAgreementLinkComponent],
      exports: [DataProcessingAgreementLinkComponent]
    }]
  }], null, null);
})();
var NotificationMedium;
(function (NotificationMedium) {
  NotificationMedium["UI"] = "ui";
  NotificationMedium["MAIL"] = "mail";
  NotificationMedium["MOBILE"] = "mobile";
})(NotificationMedium || (NotificationMedium = {}));
var CCNotificationPriority;
(function (CCNotificationPriority) {
  CCNotificationPriority["info"] = "info";
  CCNotificationPriority["okay"] = "okay";
  CCNotificationPriority["warning"] = "warning";
  CCNotificationPriority["error"] = "error";
  CCNotificationPriority["critical"] = "critical";
})(CCNotificationPriority || (CCNotificationPriority = {}));
var CCTextType;
(function (CCTextType) {
  CCTextType["plain"] = "plain";
  CCTextType["date"] = "date";
  CCTextType["time"] = "time";
  CCTextType["datetime"] = "datetime";
})(CCTextType || (CCTextType = {}));
var CCSendMethod;
(function (CCSendMethod) {
  CCSendMethod["rest"] = "rest";
  CCSendMethod["eventBridge"] = "eventBridge";
})(CCSendMethod || (CCSendMethod = {}));
class NotificationPreferencesService {
  constructor(http, configService) {
    this.http = http;
    this.configService = configService;
  }
  /** Set the sendNotification flag to enable or disable sending notifications for this application. */
  setSendNotificationsOfApp(orgId, personId, appId, params) {
    return this.http.post(`${this.getPersonOrgsAppNotificationPreferencesUrl(orgId, personId, appId)}/sendNotifications`, params).pipe(first());
  }
  /** Get the app-specific notification preferences of a user in an organization */
  getPreferencesByAppId(orgId, personId, appId) {
    return this.http.get(this.getPersonOrgsAppNotificationPreferencesUrl(orgId, personId, appId)).pipe(first());
  }
  /** Set the app-specific notification preferences of a user in an organization */
  setPreferencesOfApp(orgId, personId, appId, params) {
    return this.http.post(`${this.getPersonOrgsAppNotificationPreferencesUrl(orgId, personId, appId)}`, params).pipe(first());
  }
  /** By default, every notification is sent to the user. This method allows you to exclude certain types of notifications from being sent to a user of an organization */
  setExcludeNotificationType(orgId, personId, appId, params) {
    return this.http.post(`${this.getPersonOrgsAppNotificationPreferencesUrl(orgId, personId, appId)}/excludedNotifications/${params.type}`, params).pipe(first());
  }
  getPersonOrgsAppNotificationPreferencesUrl(orgId, personId, appId) {
    return `${this.configService.getNotificationSvcUrl()}/persons/${personId}/organizations/${orgId}/preferences/${appId}`;
  }
  static {
    this.ɵfac = function NotificationPreferencesService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NotificationPreferencesService)(i0.ɵɵinject(i1$1.HttpClient), i0.ɵɵinject(ConfigService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NotificationPreferencesService,
      factory: NotificationPreferencesService.ɵfac,
      providedIn: ControlCenterNotificationModule
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NotificationPreferencesService, [{
    type: Injectable,
    args: [{
      providedIn: ControlCenterNotificationModule
    }]
  }], () => [{
    type: i1$1.HttpClient
  }, {
    type: ConfigService
  }], null);
})();
const POLLING_INTERVAL = 10000;
class NotificationService {
  constructor(http, configService) {
    this.http = http;
    this.configService = configService;
    this.stopPolling$ = new Subject();
    this.personOrgSubject = new BehaviorSubject(undefined);
    this.isPollingActive = true;
    const poll =
    // Emit an event on interval. Query from server after every timeout.
    timer(0, POLLING_INTERVAL).pipe(
    // Query notifications from server
    switchMap(() => {
      if (this.isPollingActive) {
        return http.get(this.getPersonOrgNotificationUrl());
      }
      return of([]);
    }),
    // Retry on error
    retryWhen(genericRetryStrategy()));
    const initializedPoll = concat(of([]), poll);
    this.notifications$ =
    // Reset polling (and start with []) whenever userOrg changes.
    this.personOrgSubject.asObservable().pipe(switchMap(personOrg => personOrg === undefined ? of([]) : initializedPoll),
    // Convert notifications from internal format into a displayable format.
    map(notifications => notifications.map(convertNotification)),
    // Share across subscribers. Don't start a new http query for each subscriber. Replay the last received event for a new subscriber.
    shareReplay({
      // Stop polling when the last subscriber unsubscribes.
      refCount: true,
      // Only replay the last event.
      bufferSize: 1
    }),
    // Complete when the service stops.
    takeUntil(this.stopPolling$));
  }
  ngOnDestroy() {
    this.stopPolling$.next();
  }
  /**
   * Set the current person and organization.
   *
   * You must call this at least once in initialization before you can make requests.
   *
   *
   * ## Example
   *
   * ```ts
   * sessionService.getSessionChangedSubscription().subscribe(session => {
   *   notificationService.setCurrentUser({personId: session.id, organizationId: sessionService.getCurrentOrganisationId()});
   * });
   * ```
   */
  setCurrentUser(personOrg) {
    this.personOrg = personOrg;
    this.personOrgSubject.next(personOrg);
  }
  /** Delete a notification. */
  delete(notificationId) {
    return this.http.delete(`${this.getPersonOrgNotificationUrl()}/${notificationId}`);
  }
  /** Delete all notifications. */
  deleteAll() {
    return this.http.delete(this.getPersonOrgNotificationUrl());
  }
  /** Create and send a new notification. */
  send(notification, sendMethod, eventBridgeUrl) {
    if (sendMethod === CCSendMethod.eventBridge && eventBridgeUrl) {
      return this.http.post(eventBridgeUrl, notification);
    }
    return this.http.post(`${this.configService.getNotificationSvcUrl()}/notifications`, notification);
  }
  /** Enable and disable polling */
  setIsPollingActive(isPollingActive) {
    this.isPollingActive = isPollingActive;
  }
  getPersonOrgNotificationUrl() {
    verifyPersonOrgIsDefined(this.personOrg);
    return `${this.configService.getNotificationSvcUrl()}/persons/${this.personOrg.personId}/organizations/${this.personOrg.organizationId}/notifications`;
  }
  static {
    this.ɵfac = function NotificationService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NotificationService)(i0.ɵɵinject(i1$1.HttpClient), i0.ɵɵinject(ConfigService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NotificationService,
      factory: NotificationService.ɵfac,
      providedIn: ControlCenterNotificationModule
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NotificationService, [{
    type: Injectable,
    args: [{
      providedIn: ControlCenterNotificationModule
    }]
  }], () => [{
    type: i1$1.HttpClient
  }, {
    type: ConfigService
  }], null);
})();
function verifyPersonOrgIsDefined(personOrg) {
  if (!personOrg) {
    throw new Error('Person and Organization not set');
  }
}
function convertNotification(ccNotification) {
  const notification = {
    ...ccNotification,
    timestamp: Date.parse(ccNotification.timestamp),
    priority: ccNotification.priority,
    href: ccNotification.url
  };
  return notification;
}
const RETRY_MAX_ATTEMPTS = 10;
const RETRY_TIMEOUT_SCALAR_MS = 5000;
const genericRetryStrategy = ({
  maxRetryAttempts = RETRY_MAX_ATTEMPTS,
  scalingDuration = RETRY_TIMEOUT_SCALAR_MS,
  excludedStatusCodes = [HttpStatusCode.BadRequest, HttpStatusCode.Unauthorized, HttpStatusCode.Forbidden]
} = {}) => attempts => {
  return attempts.pipe(mergeMap((error, i) => {
    const retryAttempt = i + 1;
    // if maximum number of retries have been met or response is a status code we don't wish to retry, throw error
    if (retryAttempt > maxRetryAttempts || excludedStatusCodes.find(e => e === error.status)) {
      return throwError(() => new Error(error));
    }
    console.log(`Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration} ms`);
    return timer(retryAttempt * scalingDuration);
  }), finalize(() => console.log('Retry done!')));
};

/*
 * Public API Surface of control-center-frontend-integration
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CCNotificationPriority, CCSendMethod, CCTextType, CC_TEXTKEY_DATAPROCESSING_AGREEMENT_LABEL, CC_TEXTKEY_DATAPROCESSING_AGREEMENT_LINK, CONTROL_CENTER_SVC_URL_TOKEN, CONTROL_CENTER_UI_URL_TOKEN, ConfigService, ControlCenterNotificationModule, DataProcessingAgreementLinkComponent, LOCATION_TOKEN, LegalUtilsModule, NOTIFICATION_SVC_URL_TOKEN, NotificationMedium, NotificationPreferencesService, NotificationService, STAGE_DEV, STAGE_LOCAL, STAGE_PROD, STAGE_STAGE };
