import { Injectable } from '@angular/core';
import {
    ApiRequestType,
    PreSettingRequest,
    PreSettingResponse,
    RequestMetadata,
    mapToRequestMetadataWithRetry,
} from '@data-terminal/utils';
import { Observable, map, of, switchMap } from 'rxjs';
import { ApiRequestResponseApiService } from './api-request-response-api.service';

@Injectable({
    providedIn: 'root',
})
export class ApiRequestResponseService {
    constructor(private readonly apiRequestResponseApiService: ApiRequestResponseApiService) {}

    public sendApiRequest<T, V>(type: ApiRequestType, body: T, globalSpinner = true): Observable<RequestMetadata<V>> {
        return this.apiRequestResponseApiService
            .sendApiReqeust({
                type,
                value: body,
            })
            .pipe(
                switchMap((requestResponse) =>
                    requestResponse.requestId === 'MOCK'
                        ? of(requestResponse.value as V).pipe(
                              mapToRequestMetadataWithRetry({
                                  delayFactorFn: () => 1,
                                  baseDelayMs: 1000,
                                  maxRetries: 15,
                              })
                          )
                        : this.apiRequestResponseApiService
                              .getApiResponse<V>(requestResponse.requestId, globalSpinner)
                              .pipe(
                                  map((response) => response.value),
                                  mapToRequestMetadataWithRetry({
                                      delayFactorFn: () => 1,
                                      baseDelayMs: 1000,
                                      maxRetries: 15,
                                  })
                              )
                )
            );
    }

    public sendPreSettingRequest(body: PreSettingRequest): Observable<RequestMetadata<PreSettingResponse>> {
        return this.apiRequestResponseApiService.sendPreSettingRequest(body).pipe(
            switchMap((requestResponse) =>
                requestResponse.requestId === 'MOCK'
                    ? of(requestResponse.value as PreSettingResponse).pipe(
                          mapToRequestMetadataWithRetry({
                              delayFactorFn: () => 1,
                              baseDelayMs: 1000,
                              maxRetries: 15,
                          })
                      )
                    : this.apiRequestResponseApiService
                          .getApiResponse<PreSettingResponse>(requestResponse.requestId, false)
                          .pipe(
                              map((response) => response.value),
                              mapToRequestMetadataWithRetry({
                                  delayFactorFn: () => 1,
                                  baseDelayMs: 1000,
                                  maxRetries: 15,
                              })
                          )
            )
        );
    }
}
