import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

export function getParams(route: ActivatedRoute): { [key: string]: string } {
    if (route.firstChild === null) {
        return route?.snapshot?.params || (route?.params as BehaviorSubject<object>)?.value || {};
    }
    return getParams(route.firstChild);
}

export function getParamsSnapshot(route: ActivatedRouteSnapshot): { [key: string]: string } {
    if (route.firstChild === null) {
        return route?.params || {};
    }
    return getParamsSnapshot(route.firstChild);
}
