import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { isFooterHidden, NavigationService } from '@data-terminal/data-access';

@Directive({
    selector: '[dataTerminalFooterVisible]',
})
export class FooterVisibleDirective {
    constructor(
        private readonly templateRef: TemplateRef<any>, // eslint-disable-line
        private readonly viewContainer: ViewContainerRef,
        private readonly navigationService: NavigationService
    ) {
        this.navigationService.navData$.pipe(isFooterHidden).subscribe((value: boolean) => {
            this.handle(value);
        });
    }

    private handle(value: boolean): void {
        if (value) {
            this.viewContainer.clear();
        } else {
            if (this.viewContainer.length === 0) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
        }
    }
}
