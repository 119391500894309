import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, filter, Observable, take, timer } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BACKEND_URL, ORGANIZATION_REQUEST_PREFIX } from '@data-terminal/data-access';
import { DataTerminalError, Machine, User } from '@data-terminal/shared-models';
import { PAGE_VISIBILITY_CHANGE, takeUntilPageVisible } from '@data-terminal/utils';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class DataTerminalSessionService {
    private readonly PERSON_URL: (email: string) => string = (email) =>
        `${this.backendUrl}${this.orgRequestPrefix}person/${email}`;

    private readonly KEEP_ALIVE_URL: (userId: string) => string = (userId) =>
        `${this.backendUrl}${this.orgRequestPrefix}keepAlive/${userId}`;

    private readonly ADD_ZAIKIO_ID_URL: string = `${this.backendUrl}${this.orgRequestPrefix}addZaikioId`;

    private readonly KEEP_ALIVE_REQUEST_INTERVAL = 60 * 1000;

    #currentUser!: User;
    readonly #isDataCaptureUser$ = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly http: HttpClient,
        @Inject(BACKEND_URL) private readonly backendUrl: string,
        @Inject(ORGANIZATION_REQUEST_PREFIX) private readonly orgRequestPrefix: string,
        @Inject(PAGE_VISIBILITY_CHANGE) public readonly visibilityChange$: Observable<boolean>
    ) {}

    public logInToDataTerminal(userMail: string, orgName: string): Observable<User | DataTerminalError> {
        return this.http.get<User | DataTerminalError>(this.PERSON_URL(userMail)).pipe(
            tap((response) => {
                const error = response as DataTerminalError;
                const user = response as User;

                const hasUserId = !!user?.userId;
                this.#isDataCaptureUser$.next(hasUserId);

                if (!error.error && hasUserId) {
                    this.#currentUser = user;
                    this.sendZaikioIdUpdateRequest(orgName);
                }
            })
        );
    }

    public getCurrentDataTerminalUser(): User {
        return this.#currentUser;
    }

    public get isDataCaptureUser$(): Observable<boolean> {
        return this.#isDataCaptureUser$.asObservable();
    }

    public setLoggedOnMachines(machines: Machine[]): void {
        this.#currentUser.userMachines = machines;
        this.#currentUser.machinePermissions = machines.map((machine) => ({
            id: machine.machineId,
            userMachinePermissions:
                machine.signedOnUsers.find((user) => user.userId === this.#currentUser.userId)
                    ?.userMachinePermissions || [],
        }));
    }

    public keepAlive(): void {
        if (this.#currentUser) {
            this.visibilityChange$
                .pipe(
                    untilDestroyed(this),
                    filter((isVisible) => isVisible),
                    switchMap(() =>
                        timer(0, this.KEEP_ALIVE_REQUEST_INTERVAL).pipe(
                            takeUntilPageVisible(this.visibilityChange$),
                            switchMap(() => this.callKeepAlive())
                        )
                    )
                )
                .subscribe();
        }
    }

    private callKeepAlive(): Observable<boolean> {
        return this.http.get<boolean>(this.KEEP_ALIVE_URL(this.#currentUser['userId']));
    }

    private sendZaikioIdUpdateRequest(orgName: string): void {
        const requestBody = {
            userId: this.#currentUser.userId,
            organizationName: orgName,
        };
        this.http.post(this.ADD_ZAIKIO_ID_URL, requestBody).pipe(take(1)).subscribe();
    }
}
