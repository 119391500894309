// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { SHARED_ENVIRONMENT } from './shared-environment';

export const environment = {
    production: false,
    APP_CLIENT_ID: '8c0b1173-bc3d-46af-b365-33c0438a2fc0',
    HEIDELBERG_CC_URL: 'https://cc.stage.heidelberg.cloud',
    BACKEND_URL: 'https://datacapture-backend.shopfloor.dev.heidelberg.cloud/',
    ...SHARED_ENVIRONMENT,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
